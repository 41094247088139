<template>
  <div class="color-circle" style="width:16px; height:16px; border-radius:50%;" :style="{ 'background-color': color}">
     
  </div>
</template>
<script>
  export default {
    name: 'NgColorCircle',
    props: ['color'], 
  }
</script>
