<template>
  <div>
    <div class="d-flex">
      <validation-provider
        v-slot="{ errors }"
        :name="label"
        :rules="rules"
        style="width: 100%"
      >
        <v-text-field
          :value="value"
          prepend-inner-icon="mdi-email"
          v-bind="{ ...config, ...$attrs }"
          :label="$t(label) + labelRequired()"
          v-on="$listeners"
          @input="inputEvt"
          type="email"
          :error-messages="errors"
          v-if="!noLabel"
        />
        <v-text-field v-else
          :value="value"
          prepend-inner-icon="mdi-email"
          v-bind="{ ...config, ...$attrs }"
         
          v-on="$listeners"
          @input="inputEvt"
          type="email"
          :error-messages="errors"
        />
      </validation-provider>
      <ng-help v-if="help" :text="help" />
    </div>
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgEmail",
  /*data() {
    return {
      rulesDefault: [
        (value) => {
          if (!value) {
            return true;
          }
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo Electrónico Invalido";
        },
      ],
      rules: null,
    };
  },*/
  mixins: [AbstractField],
};
</script>
