<template>
  <div>
    <!-- 2023 -->
    <v-app-bar app style="box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; z-index: 998; height: 67px;" class="default-bar">
      <v-app-bar-nav-icon @click.stop="toogleDrawer()" dark color="primary"></v-app-bar-nav-icon>
      <slot name="title"></slot>
      <v-spacer></v-spacer>

      <!-- Add dropdown menu -->
      <v-menu left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-2 mr-5" small outlined v-bind="attrs" v-on="on" color="primary" text>
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item class="px-2 d-block text-center mb-2">
            <v-icon color="primary" class="mb-1">mdi-account-circle</v-icon>
            <span class="subtitle-3 d-block" v-text="$currentUser.data.name"></span>
            <span class="text-caption text--disabled">{{ lastLogin }}</span>
            <span class="text-caption text--disabled d-block">{{ ip }}</span>
          </v-list-item>
          <!-- <v-divider></v-divider>
          <v-list-item>
            <v-icon color="primary" class="me-2">mdi-account-circle</v-icon>
            <v-list-item-title>Mi perfil</v-list-item-title>
          </v-list-item> -->
          <v-divider></v-divider>
          <v-list-item @click="dialog = true">
            <v-list-item-title>Cerrar sesión</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- END | Add dropdown menu-->

    </v-app-bar>

    <!-- END | 2023-->

    <!-- <v-app-bar app color="white" elevation="1">

      <v-btn icon v-if="canBack()" @click="$router.go(-1)" class="ml-1 mb-1">
        <v-icon style="font-size: 24px;color:black;">mdi-chevron-left</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <img src="/logo.jpg" width="160" />
      <v-spacer></v-spacer>

      <v-btn @click="dialog = true" icon>
        <v-icon style="font-size: 24px;color:black;">mdi-close</v-icon>
      </v-btn>

    </v-app-bar> -->
    <!-- <div style="width:100%; background-color: #ddd; padding-top: 5px;" class="d-flex flex-column align-center title-app">

      <div class="user-app"><b v-text="$currentUser.data.name"> </b></div>
      <div style="font-size: 0.8em">Último ingreso: {{ lastLogin }}, IP {{ ip }}</div>
      <div class="d-flex align-center ml-1 mb-1" style="width: 100%">
        <v-app-bar-nav-icon @click.stop="toogleDrawer()" dark color="black"></v-app-bar-nav-icon>
        <slot name="title"></slot>
      </div>


    </div> -->

    <v-dialog v-model="dialog" persistent max-width="290" style="background-color: white !important">

      <v-card style="background-color: white !important">
        <v-card-text class="text-center">¿Esta seguro que desea salir?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialog = false">
            Cancelar
          </v-btn>
          <v-btn color="green darken-1" text @click="logout">
            Salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
  <!--v-app-bar app color="primary" elevation="2" dense flat dark>
    <v-app-bar-nav-icon @click.stop="toogleDrawer()"></v-app-bar-nav-icon>
    
    <slot name="title"></slot>
    <v-spacer></v-spacer>
    <slot name="help"></slot>


    <div class="main-toolbar">
      <slot name="actions"></slot>
    </div>
  </v-app-bar-->
</template>

<style>
.text {
  line-height: normal;
  font-weight: 400 !important;
}
</style>

<script>

export default {
  name: 'default-app-bar',
  data: () => ({
    showMenu: false,
    ip: '',
    lastLogin: '',
    dialog: false,
    items: [
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me' },
      { title: 'Click Me 2' },
    ],
  }),
  mounted() {
    this.ip = localStorage.getItem('ip');
    this.lastLogin = localStorage.getItem('lastLogin');
  },
  props: ['config'],
  methods: {
    logout() {
      this.$store.dispatch('logoutAuth');
    },
    canBack() {
      //console.log(this.$route)
      //return window.history.length > 1;
      if (this.$route.name == 'HOME') {
        return false;
      }
      return true;
    },

    toogleDrawer() {
      this.$store.commit('toggle')
    },

  }
}
</script>
