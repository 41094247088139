<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex">
      <v-select
        v-model="value"
        v-bind="config"
        :items="items" 
        
        v-on:change="change($event)"
        :label="$t(options.label) + labelRequired()" 
        :item-text="'name'" 
        
        :disabled="isDisabled()"
        :error="error" 
        :error-messages="errorMessages"
        :value-comparator="compare"
        return-object
        >
      </v-select>
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgSelectOptions',
    mixins: [AbstractField],
    data(){
      return {
        items: []
      }
    },
    created(){
      this.items = this.options.items;
    },
   computed: {
      value: {
        cache: false,
        get: function () {
          if (this.options) {
            // console.log('get value')
            if(this.form[this.options.name] && this.form[this.options.name].id){
              return this.form[this.options.name]
            }else{
              if(this.form[this.options.name]){
                for(const o of this.options.items){
                  if(o.id == this.form[this.options.name] ){
                    return o;
                  }
                }
              }
            }
          }
          return null
        },
        set: function (nv) {
          this.form[this.options.name] = nv
          // console.log('set value')
          // this.$forceUpdate()
          // this.form._dirty = true
          //this.$forceUpdate();
        }
      }
    },
  }
</script>
