<script>
import NgText from "./NgText";
export default {
  name: "NgPhone",
  mixins: [NgText],
  data() {
    return {
      prefix: "",
    };
  },
  created() {
    this.mask = "[^0-9]+";
  },
  methods: {
    getsType() {
      return "tel";
    },
  },
};
</script>

