import NgConfirm from './../components/NgConfirm'
import Vue from 'vue'

class ModalsManager{
    open(parent, data){
        return new Promise((resolve, reject) => {
            if(data){
                data['resolve'] = resolve;
            }
            const NgConfirmFactory = Vue.extend(NgConfirm);
            //console.log(parent)
            const vm = new NgConfirmFactory({
                i18n: parent.$i18n,
                propsData: data
            });
            vm.$vuetify = parent.$vuetify;
            vm.$store = parent.$store;
            vm.$mount();
        });
    }
    openShowComponent(parent, cmp, data){
        return new Promise((resolve, reject) => {
            if(data){
                data['resolve'] = resolve;
            }
            const NgConfirmFactory = Vue.extend(cmp);
            //console.log(parent)
            const vm = new NgConfirmFactory({
                i18n: parent.$i18n,
                propsData: data
            });
            vm.$vuetify = parent.$vuetify;
            vm.$store = parent.$store;
            vm.$mount();
        });
    }
}

export default new ModalsManager();