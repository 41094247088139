<template>
  <v-navigation-drawer id="core-navigation-drawer" v-model="drawer" color="#222222" mobile-break-point="600"
    style="z-index: 999" app>
    <v-list nav v-if="$currentUser.data" style="background-color: white !important">
      <v-list-item class="d-flex justify-center">
        <v-list-item>
          <img src="/logo.jpg" style="width: 180px" />
        </v-list-item>
      </v-list-item>

      <!--v-list-item>
        <v-list-item-content>
          <v-list-item-title
            class="display-1 text-center"
            v-text="$currentUser.data.name"
          />
       
        </v-list-item-content>
      </v-list-item-->
    </v-list>

    <v-list class="drawer-menu py-5" expand nav dark>
      <v-list-item dense style="color: white !important" to="/admin/home">
        <v-list-item-title dense>
          <v-icon>mdi-view-dashboard</v-icon> Panel principal
        </v-list-item-title>
      </v-list-item>

      <template v-if="$store.getters.is_investor">
        <template v-for="item in menu">
          <v-list-item v-if="item && item.children && item.children.length == 0" dense style="color: white !important"
            :to="item.url">
            <v-list-item-title dense>
              <v-icon>mdi-wallet</v-icon> {{ item.text }}
            </v-list-item-title>
          </v-list-item>

          <template v-else>
            <v-list-group class="mt-1" style="color: white !important" dense>
              <template v-slot:activator>
                <v-list-item-title style="color: white !important" dense>
                  <v-icon>mdi-wallet</v-icon> {{ item.text }}</v-list-item-title>
              </template>
              <template v-for="(subitem, i) in item.children">
                <v-list-group no-action sub-group dense v-if="subitem && subitem.children && subitem.children.length > 0
                  " class="pl-2 mt-1" v-model="subitem.active">
                  <template v-slot:activator>
                    <v-list-item style="color: white !important" dense>
                      <v-list-item-content style="color: white !important" dense>
                        <v-list-item-title style="color: white !important" dense>
                          <!-- <v-icon v-if="subitem.icon">{{subitem.icon}}</v-icon> -->
                          <v-icon v-if="subitem.icon" x-small color="primary" class="me-1">mdi-circle-outline</v-icon>
                          {{ subitem.text }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-list-item v-for="(subitem2, i) in subitem.children" :key="i" dense link :to="subitem2.url"
                    class="pl-4 mt-1">
                    <v-list-item-title dense class="ml-2">
                      <!-- <v-icon v-if="subitem2.icon" class="ml-2">{{subitem2.icon}}</v-icon> -->
                      {{ subitem2.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list-group>
                <v-list-item v-else style="color: white !important" dense :to="subitem.url" class="pl-4">
                  <v-list-item-title dense><v-icon v-if="subitem.icon" class="ml-2 mr-1">{{
                    subitem.icon
                  }}</v-icon>{{ subitem.text }}
                  </v-list-item-title>
                </v-list-item>
              </template>
            </v-list-group>


          </template>
        </template>
      </template>
    </v-list>
    <!-- <template v-slot:append>
      
    </template> -->
  </v-navigation-drawer>
</template>

<style>
#core-navigation-drawer .v-list-group__header.v-list-item--active:before {
  opacity: 0.07;
}

.theme--dark.v-list-item--active:focus::before {
  opacity: 0.07 !important;
}

#core-navigation-drawer {
  background: linear-gradient(to bottom, #222222, #2a2a2a);
}
</style>

<v-list-item to="/admin/solicitudes/list-desembolsado-owner" dense v-if="$store.getters.hasCreditos()">
     
    
            <v-list-item-content dense >
              <v-list-item-title dense > <v-icon v-text="'mdi-menu'" /> Créditos </v-list-item-title>
        
            </v-list-item-content>
          </v-list-item>

<v-list-item @click="logout()" dense >
     
    
        <v-list-item-content dense >
          <v-list-item-title dense > <v-icon v-text="'mdi-logout'" /> Salir </v-list-item-title>
    
        </v-list-item-content>
      </v-list-item>
  
      <div />
    </v-list>

  </v-navigation-drawer>

</template>

<script>
// Utilities
import {
  mapState,
} from 'vuex'

export default {
  name: 'LayoutAppDrawer',

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    "items": [

    ],
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.drawer
      },
      set(val) {
        this.$store.commit('SET_DRAWER', val)
      },
    },
    menu() {
      return this.$store.getters.menues;
    },
    auth() {
      return this.$store.getters.auth
    },
    profile() {
      return {
        avatar: true,
        title: this.$t('avatar'),
      }
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logoutAuth');
    }
  },
}
</script>

<style lang="sass">
  /* @import '~vuetify/src/styles/tools/_rtl.sass' */
  .v-overlay
    top: 54px !important
  
  core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
      opacity: .24

    .v-list-item
      &__icon--text,
      &__icon:first-child
        justify-content: center
        text-align: center
        width: 20px

        +ltr()
          margin-right: 24px
          margin-left: 12px !important

        +rtl()
          margin-left: 24px
          margin-right: 12px !important

    .v-list--dense
      .v-list-item
        &__icon--text,
        &__icon:first-child
          margin-top: 10px

    .v-list-group--sub-group
      .v-list-item
        +ltr()
          padding-left: 8px

        +rtl()
          padding-right: 8px

      .v-list-group__header
        +ltr()
          padding-right: 0

        +rtl()
          padding-right: 0

        .v-list-item__icon--text
          margin-top: 19px
          order: 0

        .v-list-group__header__prepend-icon
          order: 2

          +ltr()
            margin-right: 8px

          +rtl()
            margin-left: 8px
</style>
