import Vue from 'vue'
import Vuex from 'vuex'
import user from './store/user'
import auth from './store/auth'
import shared from './store/shared'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: true,
    company: null,
    menues: [],
    pages: new Map(),
    lists: new Map(),
    requestList: new Map(),
    mobile: false,
    infoCreditos: {"total_admon": 0,"total_creditos":0,"num_creditos_admon": 0,"total_solicitudes_admon":0,"num_solicitudes_admon":0},
    config: {}
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    setMenues (state, menues) {
      state.menues = menues;
    },
    toggle (state) {
      state.drawer = !state.drawer;
    },
    addPage (state, page) {
      if(page && page.path){
        state.pages.set(page.path, page);
      }
    },
    addList (state, list) {
      if(list && list.path){
        state.lists.set(list.path, { items: list.items, last: (new Date()).getTime() });
      }
    },
    setMobile(state, payload){
      state.mobile = payload
    },
    SET_COMPANY (state, payload) {
      state.company = payload
    },
    SET_CONFIG (state, payload) {
      state.config = payload
    },
    SET_INFO_CREDITOS(state, payload){
      state.infoCreditos = payload
    }
  },
  getters: {
    menues: state => state.menues,
    mobile: state => state.mobile,
    getZoneTax: (state) => (zoneId) => {
      if(zoneId){
        for(const z of state.config['tax-zones']){
          if(z.id == zoneId){
            return z;
          }
        }
      }
      return null;
    },
    hasCreditos: (state) => () => {
      return state.infoCreditos.total_creditos;
    },
    getTaxZoneName: (state) => (zoneId) => {
      if(zoneId){
        for(const z of state.config['tax-zones']){
          if(z.id == zoneId){
            return z.name;
          }
        }
      }
      return null;
    },
    getList: (state) => (path) => {
      if(!state.lists.has(path)){
        return null;
      }
      return state.lists.get(path);
    },

    getPageConfig: (state) => (path) => {
      if(!state.pages.has(path)){
        return null;
      }
      return state.pages.get(path);
    },
    company (state) {
      return state.company
    }
  },
  actions: {

  },
  modules: {
    user: user,
    auth: auth,
    shared: shared
  },
})

