<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-select
        :value="value"
        :loading="loading"
        v-bind="{ ...config, ...$attrs }"
        :items="items"
        :label="$t(label) + labelRequired()"
        :error-messages="errors"
        :value-comparator="compare"
        v-on="$listeners"
        @input="inputEvt"
        return-object
      >
        <template v-slot:item="{ item }">
          <span v-if="item.name">{{ item.name }}</span>
          <span v-else>{{ item.nombre }}</span>
        </template>
        <template v-slot:selection="{ item }">
          <span v-if="item.name">{{ item.name }}</span>
          <span v-else>{{ item.nombre }}</span>
        </template>
      </v-select>
    </validation-provider>
    <slot></slot>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";

export default {
  name: "NgSelect",
  mixins: [AbstractField],
  data() {
    return {
      items: [],
      loading: false
    };
  },
  props: ["url", "dependsOf", "cacheOff", "notAutoLoad"],
  created() {
    //console.log(this.notAutoLoad, this.dependsOf)
    if (!this.items || this.items.length == 0) {
      if (!this.notAutoLoad) {
       // console.log('jijijiji')
        this.loadItems();
      }
    }
    if (this.value && this.value.id && !this.items) {
      this.items = [JSON.parse(JSON.stringify(this.value))];
    }
  },
  watch: {
    value() {
      
      if (this.value && this.value.id && this.items && this.items.length == 0) {
        this.$nextTick().then(() => {
          this.items = [JSON.parse(JSON.stringify(this.value))];
          this.$emit('change', this.value);
        });
      }
    },
  },
  methods: {
    reload() {
      this.$nextTick().then(() => {
        this.loadItems();
      });
    },
    loadItems() {
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
        }
      }
      let list = this.$store.getters.getList(this.getUrlQuery(params));
      if (!list && !this.cacheOff) {
        this.queryOptions();
      } else {
        this.items = list.items;
      }
    },
    queryOptions(v) {      
      const params = {};
      if (this.dependsOf) {
        for (const index in this.dependsOf) {
          params[index] = this.dependsOf[index];
          if (!params[index]) {
            this.loading = false;
            return;
          }
        }
      }
      this.loading = true;
      this.$http
        .get2(this.getUrlQuery(), params)
        .then((data) => {
          this.$store.commit("addList", {
            path: this.getUrlQuery(params),
            items: data.items,
          });
          this.$nextTick().then(() => {
            this.loading = false;
            this.items = data.items;
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
