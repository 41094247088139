class Auth{
  constructor(http){
    this.http = http;
  }
  authenticatedUserFirebase(user) {
    return new Promise((resolve, reject) => {
      this.http.post('api/v1/auth/authenticated-user-firebase', { data: user}).then( (data) => {
        // console.log({ user: res.data.data, token: res.data.token })
        if (data.success) {
          const auth = JSON.stringify(data.data);
          localStorage.setItem('auth', auth);
          localStorage.setItem('token', data.token);
        }
        resolve(data);
      }).catch((e) => {
        reject(e);
      })
    });
  }
  authenticated(user) {
    return new Promise((resolve, reject) => {
      this.http.post('api/auth/login', { data: { email: user.user, passwd: user.passwd }}).then((data) => {
        // console.log({ user: res.data.data, token: res.data.token })
        console.log(data)
        if (data.success) {
          const auth = JSON.stringify(data.payload);
          localStorage.setItem('auth', auth);
          localStorage.setItem('token', data.token);
        }
        resolve(data);
      }).catch((e) => {
        reject(e);
      })
    });
  } 
}

export default Auth;
