

class InputHooks {
    hooks = {}
    
    contructor () {
        
    }
    
    register (hookName, callback){
        this.hooks[hookName] = callback 
    }
    
    execute (hookName, inputCompnent, inputOptions, ngForm) {
        // console.log(arguments)
        if (this.hooks[hookName]) {
            return this.hooks[hookName](...arguments);
        } else {
            console.error(' not found hook : ', hookName)
        }
    }
}

const inputHooks = new InputHooks();

  inputHooks.register('hook_requires_last_name', (hook, cmp, options, form)=>{
    //console.log('hook', hook)
    if(form && form.commons_type_identification_id && form.commons_type_identification_id.requires_last_name){
      return true;
    }
    return false;
  });

  inputHooks.register('hook_change_type_identification', (hook, cmp, options, form)=>{
        const inp = form.getInput('last_name');
        if(form && form.commons_type_identification_id && !form.commons_type_identification_id.requires_last_name){
         
          inp.config.required = false;
          inp.config.validate = [];
          inp.updateRules();
          inp.keyInput++;
          setTimeout(()=> {
            inp.testRules();
          }, 100);
    
        }else{
          if(form.config.last_name.required){
            inp.config.required = true;
            inp.config.validate = ['required'];
            inp.updateRules();
            inp.keyInput++;
            setTimeout(()=> {
              inp.testRules();
            }, 100);
          }
        }
  });

  inputHooks.register('hook_is_variant_colour', (hook, cmp, options, form)=> {

    if(form && form.inv_variant_id && form.inv_variant_id.is_colour){
      return true;
    }
    return false;
  })

export default inputHooks;
