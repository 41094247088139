<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div :key="key" class="d-flex">
      <v-select
        v-model="value"
        v-bind="config"
        multiple
        :items="items" 
        
        v-on:change="change($event)"
        :label="$t(options.label) + labelRequired()" 
        :item-text="'name'"
        :disabled="isDisabled()"
        :error="error" 
        :error-messages="errorMessages"
        :value-comparator="compare"
        return-object
        >

      </v-select>
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgSelectMulti',
    mixins: [AbstractField],
    data(){
      return {
        items: null,
        key: 0
      }
    },
    /*created(){
      if(!this.items){
        if(this.value && this.value.length > 0){
          this.items = JSON.parse(JSON.stringify(this.value));
        }
        const last = this.$store.getters.getRequestList(this.options.url);
        if(!last){
          this.queryOptions('');
        }else{
            const list = this.$store.getters.getList(this.options.url);
            if(list){
              this.items = list.items;
              this.keyReload++;
            }
        }
      }
     },*/
    created(){
      if(!this.items){
        if(this.value && this.value.id && !this.items){
          this.items = [JSON.parse(JSON.stringify(this.value))];
        }
       // console.log(this.options)
       if(!this.options.blank ){
          const list = this.$store.getters.getList(this.options.url);
          if(!list){
            this.queryOptions('');
          }else{
            this.items = list.items;
            this.key++;
          }
        }
      }
     },
     methods: {
      formChange (evt) {

        //console.log('change: ' + this.options.name, this.form[this.options.name], this.value)
        //console.log('select-multi change ', evt.control, this.options.name, this.options)
        if(this.options.depends_of){

          if(this.options.depends_of.indexOf(evt.control) > -1){
            this.updateItems();
          }
        }
      },
      updateItems(){
        this.queryOptions('');
      },
      queryOptions (v) {
        //this.$store.commit('addRequestList', this.options.url);
        this.loading = true
        const params = this.options.params || {};
        if(this.options.depends_of){
            for(const index of this.options.depends_of){
                params[index]= this.form[index];
            }
        }
        if(v){
          params['query'] = v;
        }
        this.$http.post( this.options.url, { data: params }).then(data => {
          const { items } = data
          this.items = items;
          //this.$store.commit('deleteRequestList', this.options.url);
          this.$store.commit('addList', { path: this.options.url, items});
          this.keyReload++;
        }).catch(err => {
          console.log(err)
        }) .finally(() => (this.loading = false))          
      }
    }
  }
</script>
