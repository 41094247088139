import * as firebase from 'firebase'

export default {
  state: {
    user: null,
  },
  mutations: {
    setUser (state, payload) {
      state.user = payload

    },
  },
  actions: {
    signUserUp ({ commit }, payload) {
      commit('setLoading', true)
      commit('clearError')
      firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        .then(
          user => {
            // commit('setLoading', false)
            const newUser = {
              uid: user.uid,
              name: user.displayName,
              email: user.email,
              photoUrl: user.photoURL,
            }
            commit('setUser', newUser)
          },
        )
        .catch(
          error => {
            //commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
    },
    signUserIn ({ commit }, payload) {
      //commit('setLoading', true)
      commit('clearError')
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then(
            response => {
            //commit('setLoading', false)
            const newUser = {
              uid: response.user.uid,
              name: response.user.displayName,
              email: response.user.email,
              photoUrl: response.user.photoURL
            }
            commit('setUser', newUser)
          }
        )
        .catch(
          error => {
            //commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
    },
    signUserInGoogle ({ commit }) {
      //commit('setLoading', true)
      commit('clearError')
      firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .then(
          response => {
            // console.log(response)
            //commit('setLoading', false)
            const newUser = {
              uid: response.user.uid,
              name: response.user.displayName,
              email: response.user.email,
              type: 'google',
              photoUrl: response.user.photoURL
            }
            commit('setUser', newUser)
          }
        )
        .catch(
          error => {
            //commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
    },
    signUserInFacebook ({ commit }) {
      //commit('setLoading', true)
      commit('clearError')
      firebase.auth().signInWithPopup(new firebase.auth.FacebookAuthProvider())
        .then(
          response => {
            //commit('setLoading', false)
            const newUser = {
              uid: response.user.uid,
              name: response.user.displayName,
              email: response.user.email,
              type: 'facebook',
              photoUrl: response.user.photoURL
            }
            commit('setUser', newUser)
          }
        )
        .catch(
          error => {
            //commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
    },
    signUserInGithub ({ commit }) {
      //commit('setLoading', true)
      commit('clearError')
      firebase.auth().signInWithPopup(new firebase.auth.GithubAuthProvider())
        .then(
          response => {
            //commit('setLoading', false)
            const newUser = {
              uid: response.user.uid,
              name: response.user.displayName,
              email: response.user.email,
              type: 'github',
              photoUrl: response.user.photoURL
            }
            commit('setUser', newUser)
          }
        )
        .catch(
          error => {
            //commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
    },
    signUserInTwitter ({ commit }) {
      //commit('setLoading', true)
      commit('clearError')
      firebase.auth().signInWithPopup(new firebase.auth.TwitterAuthProvider())
        .then(
          response => {
            //commit('setLoading', false)
            const newUser = {
              uid: response.user.uid,
              name: response.user.displayName,
              email: response.user.email,
              type: 'twitter',
              photoUrl: response.user.photoURL
            }
            commit('setUser', newUser)
          }
        )
        .catch(
          error => {
            //commit('setLoading', false)
            commit('setError', error)
            console.log(error)
          }
        )
    },
    autoSignIn ({ commit }, payload) {
      /*commit('setUser', {
        id: payload.uid,
        name: payload.displayName,
        email: payload.email,
        type: payload.type,
        photoUrl: payload.photoURL
      })*/
      commit('setUser', payload)
    },
    resetPasswordWithEmail ({ commit }, payload) {
      const { email } = payload
      //commit('setLoading', true)
      firebase.auth().sendPasswordResetEmail(email)
      .then(
        () => {
          //commit('setLoading', false)
          console.log('Email Sent')
        }
      )
      .catch(
        error => {
          //commit('setLoading', false)
          commit('setError', error)
          console.log(error)
        }
      )
    },
    logout ({ commit }) {
      firebase.auth().signOut()
     /* localStorage.removeItem('auth')
      localStorage.removeItem('token')*/
      commit('setUser', null)
      /*commit('setAuth', null)
      console.log('logout')
      window.location.href = '/auth/login'*/
    }
  },
  getters: {
    user (state) {
      return state.user
    }
  }
}
