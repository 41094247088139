<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-show="isVisible()"
    :key="keyInput"
    
  >
  <div class="d-flex">
      <v-text-field 
        v-model="value"
        v-bind="config"
        :label="$t(options.label) + labelRequired()"
        :type="getType()"
        @keypress="onKeypress"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"

      />
      
      <ng-help v-if="options.help" :text="options.help" />
    

    </div>
  </v-col>
  <!--v-col
    cols="12"
    
    class="form-col"
    v-show="isVisible()"
    :key="keyInput"
    
  >
    <div class="d-flex">
      <v-text-field 
        v-model="value"
        v-bind="config"
        :label="$t(options.label) + labelRequired()"
        @keypress="onKeypress"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"
      />
      
      <ng-help v-if="options.help" :text="options.help" />
    

    </div>
  </v-col-->
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgText',
    mixins: [AbstractField],
    computed: {
      value: {
        cache: false,
        get: function () {
          if (this.options) {
            // console.log('get value')
            
            
            if (this.options.upper) {
              if (this.form[this.options.name]) {
                return this.form[this.options.name].toUpperCase();
              }
              return this.form[this.options.name]
            } else {
              return this.form[this.options.name]
            }
          }
          return null
        },
        set: function (nv) {
          //console.log(this.options)
           if (this.options.upper) {
              if (nv) {
                this.form[this.options.name] = nv.toUpperCase();
              }
            }else{
                this.form[this.options.name] = nv
            }
          // console.log('set value')
          // this.$forceUpdate()
          // this.form._dirty = true
          //this.$forceUpdate();
        }
      }
    },
    methods: {
      getType(){
        if(this.options.type){
          return this.options.type;
        }

        return 'text';
      },
      onKeypress (event) {
        const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16]
        if (ignore.indexOf(event.keyCode) > -1) {
          return true
        }
        if (this.options.mask) {
          const patt = new RegExp(this.options.mask)
          const char = String.fromCharCode(event.keyCode)
          if (!patt.test(char)) {
            event.preventDefault()
          }
        }
      }
    }
  }
</script>
