
const NgValidators = {
  required: function (params) {
    return function (v, form) {
      // console.log(v)
      if (typeof v != 'undefined' && v != null && v != '' /*|| v == false*/) {
        return true
      }
      return 'Campo Obligatorio'
    }
  },
  /*
      "validate": [
      "required_if_primera_asistencia"
    ],
    */
  'required_if_primera_asistencia': function (params) {
    return function (v, form) {
      //console.log(form.value)
      if(form && form.value && form.value.primera_asistencia){
        if (((typeof v != 'undefined' && v != null && v != '') || v == true)){
          return true
        }
        return 'Campo Obligatorio'
      }
      return true;
      //
    }
  },
  'required-check': function (params) {
    return function (v, form) {
      if ((typeof v != 'undefined' && v != null && v != '') || v == true) {
        return true
      }
      return 'Campo Obligatorio'
    }
  },
  'required-radio': function (params) {
    return function (v, form) {
      if (typeof v != 'undefined' && v != null && v != '' || v == false) {
        return true
      }
      return 'Campo Obligatorio'
    }
  },
  'required-by-type': function (params) {
    return function (v, form) {
    
      if (form.value.type_identification && (form.value.type_identification.id == 21 || form.value.type_identification.id == 51)){
        return true;
      }
   
      if (typeof v != 'undefined' && v != null && v != '') {
        return true
      }
      return 'Campo Obligatorio'
    }
  },
  length: function (params) {
    // console.log(params)
    return function (v, form) {
      if(!v && params[1] == 0){
        return true;
      }
      if(v && v.length >= params[1] && v.length <= params[2]) {
        return true;
      }
      return `Debe tener entre ${params[1]} y ${params[2]} dígitos`
    }
  }
}

function getRuleValidation (rule) {
  let parts = []
  if (rule.indexOf(',') >= -1) {
    parts = rule.split(',')
  } else {
    parts.push(rule)
  }
  return NgValidators[parts[0]](parts)
}

export { NgValidators,  getRuleValidation}