//import NgConfirm from './../components/NgConfirm'
//import Vue from 'vue'


const TaxesHooks = {};

TaxesHooks['IVA'] = (tax, price) => {
  const base  = (tax.base * price / 100);
  //console.log(base, tax.percentage)
  return (base * parseFloat(tax.percentage) / 100);
}


TaxesHooks['ICO'] = (tax, price) => {
  const base  = (tax.base * price / 100);
  return (base * parseFloat(tax.percentage) / 100);  
}

class TaxManager {
    
    constructor($store){
      this.$store = $store;
    }


    calculateTaxes(zonaId, price){
      const zona = this.$store.getters.getZoneTax(zonaId);
      //console.log(zona, zonaId)
      let taxes = null;
      if(zona && zona.taxes && zona.taxes.length > 0){
        taxes = [];
        for(const tax of zona.taxes){
          //taxes.push();
          const t = { id: tax.id, name: tax.name, type: tax.type, percentage: tax.percentage };
          t.value = TaxesHooks[tax.type](tax, price);
          taxes.push(t);
        }
      }
      
      return taxes;

    }
    
}

export default  TaxManager;