import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const ifAuthenticated = (to, from, next) => {
  if (localStorage.getItem('auth')) {
    next()
    return
  }
  next('/auth/login')
}

export default new Router({
  // mode: 'hash',
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'Login',
      component: () => import('@/modules/auth/components/Login')
    },
    {
      path: '/register',
      name: 'Register',
      component: () => import('@/modules/User/UserRegistrationForm')
    },
    {
      path: '/register/:type',
      name: 'RegisterType',
      component: () => import('@/modules/User/UserRegistrationForm')
    },

    {
      path: '/auth/recover',
      name: 'Recover',
      component: () => import('@/modules/auth/components/Recover')
    },
    {
      path: '/auth/terms',
      name: 'Terms',
      component: () => import('@/modules/auth/components/Terms')
    },
    {
      path: '/payments',
      name: 'Payments',
      component: () => import('@/modules/payments/PaymentCredit')
    },
    {
      path: '/payments/:id',
      name: 'Payments',
      component: () => import('@/modules/payments/PaymentCredit')
    },
    {
      path: '/payments/finalize/:id',
      name: 'PaymentsFinalize',
      component: () => import('@/modules/payments/PaymentFinalize')
    },
    /*{
      path: '/auth',
      component: () => import('@/modules/payments/PaymentCredit'),
      children: [
        {
          name: 'Login',
          path: 'login',
          component: () => import('@/modules/auth/components/Login'),
        }
      ]
    },*/
    /*{
      path: '/reserva',
      component: () => import('@/modules/reservas/Index'),
      children: [
        {
          path: '',
          component: () => import('@/modules/reservas/components/Booking'),
        },
      ]
    },*/
    {
      path: '/admin',
      beforeEnter: ifAuthenticated,
      component: () => import('@/modules/base/Index'),
      children: [
        {
          path: 'home',
          name: 'HOME',
          component: () => import('@/modules/base/Home'),
        },
        {
          path: 'user-update',
          name: 'user-update',
          component: () => import('@/modules/User/CustomerPage')
        },
        {
          path: 'company/config/:id',
          component: () => import('@/modules/source/components/company/CompanyConfig'),
        },
        {
          path: 'report/report-detalle-cartera-edades/list',
          component: () => import('@/modules/serprogreso/components/reports/NgFormReportDetalleCarteraEdades'),
        },
        {
          path: 'report/desembolsos/list',
          component: () => import('@/modules/serprogreso/components/reports/Desembolsos'),
        },
        {
          path: 'report/rentabilidad-detail/:id',
          component: () => import('@/modules/serprogreso/components/reports/RentabilidadDetalle'),
        },
        {
          path: 'report/rentabilidad/list',
          component: () => import('@/modules/serprogreso/components/reports/Rentabilidad'),
        },
        {
          path: 'report/pagos/list',
          component: () => import('@/modules/serprogreso/components/reports/Pagos'),
        },
        {
          path: 'report/ingresos/list',
          component: () => import('@/modules/serprogreso/components/reports/Ingresos'),
        },
        {
          path: 'gestion-cobro/list',
          component: () => import('@/modules/serprogreso/components/Dashboard'),
        },
        {
          path: 'gestion-clientes/list',
          component: () => import('@/modules/serprogreso/components/NgGestionCobroClientes'),
        },
        {
          path: 'seguimiento-credito/list-detail',
          component: () => import('@/modules/serprogreso/components/NgFormReportSeguimientoDetallado'),
        },
        {
          path: 'liquidacion/show/:id',
          component: () => import('@/modules/serprogreso/components/ShowLiquidacion'),
        },
        {
          path: 'solicitudes/show/:id',
          component: () => import('@/modules/serprogreso/components/ShowCredit'),
        },
        {
          path: 'solicitudes/study/:id',
          component: () => import('@/modules/serprogreso/components/Study'),
        },
        {
          path: 'solicitudes/detail/:id',
          component: () => import('@/modules/serprogreso/components/DetailCredit'),
        },
        {
          path: 'solicitudes/study-pend/:id',
          component: () => import('@/modules/serprogreso/components/Study'),
        },
        {
          path: 'solicitudes/desembolso/:id',
          component: () => import('@/modules/serprogreso/components/Desembolso'),
        },
        {
          path: 'menus-perfil',
          component: () => import('@/modules/auth/components/MenuesPerfil'),
        },
        {
          path: 'perms-perfil',
          component: () => import('@/modules/auth/components/PermissionsProfile'),
        },
        {
          path: ':module/list',
          component: () => import('@/modules/base/components/DefaultModuleList'),
        },
        {
          path: ':module/list-:extra',
          component: () => import('@/modules/base/components/DefaultModuleList'),
        },
        {
          path: ':module/edit/:id',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/edit-:extra/:id',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/new',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/new-:extra',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
      ]
    },
    {
      path: '/commons',
      beforeEnter: ifAuthenticated,
      component: () => import('@/modules/base/Index'),
      children: [
        {
          path: ':module/list',
          component: () => import('@/modules/base/components/DefaultModuleList'),
        },
        {
          path: ':module/list-:extra',
          component: () => import('@/modules/base/components/DefaultModuleList'),
        },
        {
          path: ':module/edit/:id',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/edit-:extra/:id',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/new',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/new-:extra',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
      ]
    },
    {
      path: '/config',
      beforeEnter: ifAuthenticated,
      component: () => import('@/modules/base/Index'),
      children: [
        {
          path: ':module/list',
          component: () => import('@/modules/base/components/DefaultModuleList'),
        },
        {
          path: ':module/list-:extra',
          component: () => import('@/modules/base/components/DefaultModuleList'),
        },
        {
          path: ':module/edit/:id',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/edit-:extra/:id',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/new',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
        {
          path: ':module/new-:extra',
          component: () => import('@/modules/base/components/DefaultModuleForm'),
        },
      ]
    },
    {
      path: '/',
      component: () => import('@/modules/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '/pages/dashboard',
          component: () => import('@/modules/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/modules/examples/component/UserProfile'),
        },

        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/modules/examples/component/Notifications'),
        },
        {
          name: 'Buttons',
          path: 'components/buttons',
          component: () => import('@/modules/examples/component/Buttons'),
        },
        {
          name: 'Tabs',
          path: 'components/tabs',
          component: () => import('@/modules/examples/component/Tabs'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/modules/examples/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/modules/examples/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/modules/examples/component/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/modules/examples/component/GoogleMaps'),
        },
        // Upgrade
        {
          name: 'Upgrade',
          path: 'upgrade',
          component: () => import('@/modules/dashboard/Upgrade'),
        },
      ],
    },
  ],
})
