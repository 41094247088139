const RateHooks = {};

RateHooks['IVA'] = (tax, price) => {
  //const base  = (tax.base * price / 100);
  //return base * parseFloat(tax.percentage) / 100;
}


/*RateHooks['ICO'] = (tax, price) => {
  const base  = (tax.base * price / 100);
  return base * parseFloat(tax.percentage) / 100;  
}*/

class PriceManager {
    
    constructor(taxManager){
      this.taxManager = taxManager;
    }

    calculateTaxes(price, zoneId){

      const taxes = this.taxManager.calculateTaxes(zoneId, price);
      
      return taxes;

    }


    calculateSalePrice(price, taxes){
      //product.price = parseFloat(product.price);
      /*const zona = this.$store.getZoneTax(zonaId);
      let taxes = null;
      if(zona.taxes && zona.taxes.length > 0){
        taxes = [];
        for(const tax of zona.taxes){
          //taxes.push();
          const t = { id: tax.id, name: tax.name, type: tax.type, percentage: tax.percentage };
          t.value = TaxesHooks[tax.type](tax, price);
          taxes.push(t);
        }
      }
      return taxes;*/
      //product.original_price = product.price;

      /*if(!product.acc_tax_zone_id){
        product.sale_price = product.price;
        return product.sale_price;
      }*/

      //const zoneId = product.acc_tax_zone_id.id || product.acc_tax_zone_id;
      // const taxes = this.taxManager.calculateTaxes(zoneId, product.price);
      let totalTaxes = 0;
      if(taxes){
        for(const t of taxes){
          totalTaxes += parseFloat(t.value);
        }
      }

      //product.sale_price = totalTaxes + product.price;
      //this.product.sale_price = price + totalTaxes;
      return price + totalTaxes;

    }
    
}

export default  PriceManager;