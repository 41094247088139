import { VCard, VCardText, VCardActions, VListItem, VCardTitle, VListItemTitle, VListItemSubtitle, VListItemContent, VIcon, VContainer, VRow, VCol, VBtn, VDialog, VDataTable, VSimpleTable, VCombobox, VSelect, VPagination, VDivider} from 'vuetify/lib';

class ManagerHooks {
    hooks = {}
    
    contructor () {
        
    }
    
    register (hookName, callback){
      this.hooks[hookName] = callback 
    }
    
    hasHook (hookName){
      return this.hooks[hookName] ;
    }

    executeHook (hookName) {
       if (this.hooks[hookName]) {
            return this.hooks[hookName](...arguments);
        } else {
            console.error(' not found hook : ', hookName)
        }        
    }
    
    execute (hookName, inputCompnent, inputOptions, ngForm) {
        // console.log(arguments)
        if (this.hooks[hookName]) {
            return this.hooks[hookName](...arguments);
        } else {
            console.error(' not found hook : ', hookName)
        }
    }
    executeModuleAction (hookName, action, item, component) {
        // console.log(arguments)
        if (this.hooks[hookName]) {
            return this.hooks[hookName](...arguments);
        } else {
            console.error(' not found hook : ', hookName)
        }
    }
}

window['managerHooks'] = new ManagerHooks();

window['managerHooks'].register("formValidate", function (hook, form, component) {
    //component.$router.push(action.path); 
    //console.log(hook, form._valid)
    return !form._valid;   
})

window['managerHooks'].register('hook_new', function (hook, action, item, component) {
    const router = component.$router;
    const route = router.history.current.path;
    if(route.indexOf('/new') > -1){
        component.form.clean();
    }else{
        component.$router.push(action.path);    
    }
})

window['managerHooks'].register('hook_list', function (hook, action, item, component) {
    component.$router.push(action.path);    
})

window['managerHooks'].register('hook_show', function (hook, action, item, component) {
    //component.$router.push(action.path + '/' + item.id);
    console.log(item)
    const configShow = component.$store.getters.getPageConfig(action.path);
    const cmp = {
        data(){
            return {
                dialog: true
            }
        },
        components: { VCard, VCardText, VCardActions, VCardTitle, VListItem, VListItemTitle, VListItemSubtitle, VListItemContent, VIcon, VContainer, VRow, VCol, VBtn, VDialog, VDataTable, VSimpleTable, VCombobox, VSelect, VPagination, VDivider },
        props: ['item', 'pageConfg'],
        template: `
         <v-dialog v-model="dialog" :light="true" :dark="false"  max-width="290">
      <v-card>
 
     
            ${configShow.tmpl}
        
        <v-card-actions class="d-flex justify-center">
          <v-btn color="green darken-1" @click="dialog = false" text >Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> 

        `
    }
    const modal = component.$modalsManager.openShowComponent(component, cmp,  {item, pageConfg: configShow});
})

window['managerHooks'].register('hook_edit', function (hook, action, item, component) {
    component.$router.push(action.path + '/' + item.id);    
})

window['managerHooks'].register('hook_delete', function (hook, action, item, component) {
    //console.log(action)
    const modal = component.$modalsManager.open(component, { text: 'Esta seguro que desea eliminar este registro?'});
    modal.then((response) => {
        if(response){
            //console.log('ok')
            //component.$store.commit('setLoading', true);
            component.$http.delete(action.url + '/' +item.id, null, component, true, false).then((data) => {
                console.log(data)
                //component.$store.commit('setLoading', false);
                if (data.success) {
                    const msg = data.msg || 'El registro se elimino correctamente';
                    component.$store.commit('setMessage', { text: msg});
                    component.search();
                } /*else {
                    if (res.data.msg) {
                        component.$store.commit('setError', res.data.msg);
                    } else {
                        component.$store.commit('setError', 'El registro no pudo ser eliminado.');
                    }
                }*/
            }).catch((e) => {
                //component.$store.commit('setLoading', false);
                //component.$store.commit('setError', e);
            })                   
        }
    });  
    //const configAction = component.pageConfg.actions.new;
   // const page = component.$store.getters.getPageConfig(configAction.path);
   // component.$router.push(action.path);    
})

window['managerHooks'].register('hook_save', function (hook, action, item, component) {
    //component.showLoading();
    component.$http.post( action.url, { data: item }, component, true, true )
      .then(data => {
          if(data.success){
            if(data.item){
              component.loadForm(data.item);
              component.$emit('saved', data.item)
            }
          }
      }).catch(err => {
        console.log(err);
      });
    
});

window['managerHooks'].register('hook_edit_modal', function (hook, action, item, component) {

    component.$parent.itemEdit = item;
    component.$parent.dialogEdit = true;
})

window['managerHooks'].register('hook_save_modal', function (hook, action, item, component) {

    component.$http.post( action.url, { data: item }, component, true, true )
      .then(data => {

          if(data.success){
            // component.showMessage({ text: 'El registro se guardo correctamente', color: 'success'});
            if(data.item){
              component.loadForm(data.item);
              component.$emit('saved', data.item)
            }
          }/*else{
            if(res.data.msg){
              component.showError(res.data.msg);
            }else{
              component.showError('El registro guardo correctamente');
            }
          }*/
          
      }).catch(err => {
        //component.hideLoading();
        console.log(err);
      });
    
});

//import { Plugins } from '@capacitor/core';

//const { Browser } = Plugins;

window['managerHooks'].register('hook_show_solicitud_desembolsado', function(event, action, item, component){
   component.$router.push( '/admin/solicitudes/show/' + item.id);
});

window['managerHooks'].register('hook_credit_detail', function(event, action, item, component){
   component.$router.push( '/admin/solicitudes/detail/' + item.id);
});
window['managerHooks'].register('hook_print_pdf_informe', function(event, action, item, component){
   component.$router.push( '/admin/solicitudes/study/' + item.id);
});

window['managerHooks'].register('hook_print_pdf_cuenta_cliente', function(event, action, item, component){
    //component.$router.push( '/admin/solicitudes/study/' + item.id);
    window.open('https://reportes.serprogreso.com/' + 'report/pdf/info-desembolso/'+item.id, '_blank' )
});

window['managerHooks'].register('hook_print_pdf_comprobante_desembolso', function(event, action, item, component){
    window.open('https://reportes.serprogreso.com/' + 'report/pdf/comprobante/'+item.id+'/'+item.bus_person_cliente_id.id, '_blank' )
});

window['managerHooks'].register('hook_print_pdf_informe_pend', function(event, action, item, component){
   component.$router.push( '/admin/solicitudes/study-pend/' + item.id);
});

window['managerHooks'].register('hook_show_liquidacion', function(event, action, item, component){
  //console.log('jijijijiji')
   component.$router.push( '/admin/liquidacion/show/' + item.id);
});

window['managerHooks'].register('hook_print_pdf_credito', function(event, action, item, component){
  //window.open('https://reportes.serprogreso.com/' + 'report/html/informe-estudio/'+item.id, '_blank' )
  //const url = 'https://reportes.serprogreso.com/' + 'report/html/informe-estudio/'+item.id;
    //var win = window.open(url);   
   //// win.window.print(); 
   //  Browser.open({ url });
   
   component.$router.push( '/admin/solicitudes/desembolso/' + item.id);

});

window['managerHooks'].register('hook_print_pdf_estado_cuenta', function(event, action, item, component){
   window.open('https://reportes.serprogreso.com/' + 'report/pdf/estado-cuenta/'+item.id, '_blank' )
});
