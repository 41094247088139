<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex align-center">
        <v-checkbox
            v-model="value" 
            v-bind="config"
            :label="$t(options.label)"
            @click="change"
        ></v-checkbox>
        <ng-help v-if="options.help" :text="options.help" class="ml-2"/>
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgCheck',
    mixins: [AbstractField]
  }
</script>
