<template>
  <div>
  <div :key="keyForm">
    <slot></slot>
  </div>
  </div>
</template>
<script>
  export default {
    name: 'NgForm',
    data(){
      return {
        keyForm: 0,
        key: 0
      }
    },
    props: {
      form: Object
    },
    created(){
      //this.form.on('init', this.formChange.bind(this))
    },
    methods: {
      checkInputsDependencies(){
        // console.log('***', this.form._inputs.length)
        for (const inp of this.form._inputs) {
          if (inp.config.show /*&& inp.config.disabled */) {
            inp.$forceUpdate()
          }
        }
      },
      formChange(value){
        console.log('....change form...')
        this.keyForm++;
      },
      updateKey(){
        console.log('....update key')
        this.keyForm++;
      }
    }
  }
</script>
