import Vue from 'vue'
import VueI18n from 'vue-i18n'

import es from 'vuetify/lib/locale/es';
import { configure } from 'vee-validate';
import validationMessages from 'vee-validate/dist/locale/es';


import en from 'vuetify/lib/locale/en'

Vue.use(VueI18n)

const messages = {
  en: {
    ...require('@/locales/en.json'),
    $vuetify: en,
  },
  es: {
    ...require('@/locales/es.json'),
    $vuetify: es,
    validations: validationMessages
  },
}

const i18n = new VueI18n({
  locale: 'es', //process.env.VUE_APP_I18N_LOCALE ||
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages,
  silentTranslationWarn: true
})

configure({
  // this will be used to generate messages.
  defaultMessage: (field, values) => {
    values._field_ = i18n.t(`${field}`);
    return i18n.t(`validations.messages.${values._rule_}`, values);
  }
});

/*export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
})*/
export default i18n;
