<template>
  <div class="d-flex">
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="290px"
      max-width="290px"
    >
      <template v-slot:activator="{ on }">
        <validation-provider
          v-slot="{ errors }"
          :name="label"
          :rules="rules"
          style="width: 100%"
        >
          <v-text-field
            :value="value"
            v-bind="{ ...config, ...$attrs }"
            prepend-inner-icon="mdi-clock-outline"
            :label="$t(label) + labelRequired()"
            :error-messages="errors"
            readonly
            @input="inputEvt"
            v-on="on"
          ></v-text-field>
        </validation-provider>
      </template>
      <v-time-picker
        :value="value"
        ref="picker"
        ampm-in-title
        full-width
        :max="max"
        @change="save"
        @input="menu = false"
        @click:minute="$refs.menu.save(value)"
      >
      </v-time-picker>
    </v-menu>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgTime",
  mixins: [AbstractField],
  data: () => ({
    date: "", //new Date().toISOString().substr(0, 10),
    menu: false,
    modal: false,
    max: null,
  }),
  methods: {
    save(evt) {
      this.$emit("input", evt);
    },
  }
};
</script>
