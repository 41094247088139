<template>
    <v-col cols="12" :md="options.size" class="form-col" v-if="isVisible()">
    <div class="d-flex">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false"  transition="scale-transition" offset-y  min-width="290px" >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
          class="mr-2"
            v-model="valuef.from"
            v-bind="config"
            prepend-inner-icon='mdi-calendar'
            :label="$t(options.label) + labelRequired()"
            placeholder="Desde" 
            :disabled="isDisabled()"
            :error="error" 
            :error-messages="errorMessages"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="valuef.from" ref="picker" type="date"   min="1920-01-01" :max="max" @change="save" @input="menu = false"  no-title>
        </v-date-picker>
      </v-menu>
      
      <v-menu ref="menuTo" v-model="menuTo" :close-on-content-click="false"  transition="scale-transition" offset-y  min-width="290px" >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="valuef.to"
            v-bind="config"
            prepend-inner-icon='mdi-calendar'
            label=""
            placeholder="Hasta" 
            :disabled="isDisabled()"
            :error="error" 
            :error-messages="errorMessages"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="valuef.to" ref="picker" type="date"   min="1920-01-01" :max="max" @change="saveTo" @input="menuTo = false"  no-title>
        </v-date-picker>
      </v-menu>
    </div>
    </v-col>

</template>
<script>


  import AbstractField from './AbstractField'
  export default {
    name: 'NgDateRange',
    mixins: [AbstractField],
    data: () => ({
      menu: false,
      menuTo: false,
      max: null,
      valuef : { from: null, to: null }  
      //value: { from: null, to: null }  
    }),
    mounted(){
      /*console.log('created.................')
      if (this.options && (this.form[this.options.name].from || this.form[this.options.name].to)) {
        this.value = this.form[this.options.name];
      }else{
        this.value = { from: null, to: null }  
      }*/
      // this.valuef = { from: null, to: null }  
      if (this.options &&  this.form[this.options.name] && (this.form[this.options.name].from || this.form[this.options.name].to)) {
        this.valuef = this.form[this.options.name];
      }
    },
    methods: {
      save (date) {
        const old = Object.assign({}, this.valuef)
        this.valuef.from = date;
        this.value = this.valuef;
        this.form.emmit('change', { control: this.options.name, old_value: old, new_value: this.valuef,  value: this.form.value})
      },
      saveTo(date) {
        //console.log(date)
        const old = Object.assign({}, this.valuef)
        this.valuef.to = date
        this.value = this.valuef;
        //console.log(date, this.valuef)
        this.form.emmit('change', { control: this.options.name, old_value: old, new_value: this.valuef,  value: this.form.value})
      }
    }
  
  }
</script>
