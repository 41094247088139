<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
    
  >
    <div>
        <v-checkbox
            v-model="value" 
            :false-value="null"
            v-bind="config"
            :label="$t(options.label) + '*'"
            @click="change"
        >
         <template v-slot:label>
        <div>
          Confirmo que autorizo el registro de información de datos personales y acepto 
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a
                target="_blank"
                :href="options.url"
                @click.stop
                v-on="on"
              >
                las politicas de privacidad y proteccion de datos personales.
              </a>
            </template>
          </v-tooltip>
        </div>
      </template>
        </v-checkbox>
       
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgDataPolicy',
    mixins: [AbstractField]
  }
</script>
