<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex">
      <v-textarea
        v-model="value"
        v-bind="config"
        :label="$t(options.label) + labelRequired()"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"
      ></v-textarea>
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgTextarea',
    mixins: [AbstractField]
  }
</script>
