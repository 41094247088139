/*const datetimeFormat = function (str) {
    if (str && str != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return moment.tz(str, "America/Bogota").utc().format("MMM D/ YYYY h:mm a");

    }
    return "";
};

Vue.filter('datetime', datetimeFormat);
const dateFormat = function (str) {
    if (str && str != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return moment.tz(str, "America/Bogota").utc().format("MMM D/ YYYY");

    }
    return "";
};
Vue.filter('date', dateFormat)

const booleanFormat = function (str) {
    if (str != null) {
        if(str){
            return 'SÍ';
        }else{
            return 'NO';
        }
    }
    return "";
};
Vue.filter('boolean', booleanFormat)*/
import currency from 'currency.js';
import * as moment  from 'moment-timezone';

export default {
  currency(str) {
    if (str && str != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return currency(str, { precision: 2 }).format();

    }
    return currency("0", { precision: 2 }).format();
  },
  ceil10(str){
    if (str && str != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return Math.round(str*10)/10

    }
    return "";
  },
  ceil100(str){
    if (str && str != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return Math.round(str*100)/100

    }
    return "";
  },
  nameObject(obj) {
    // console.log(obj)
    if (obj && obj.name != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return  obj.name;

    }
    return "";
  },
  date(str, format) {
    if(!format){
      format = "MMM D/ YYYY";
    }
    if (str && str != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return moment.tz(str, "America/Bogota").utc().format(format);

    }
    return "";
  },
  datetime (str) {
    if (str && str != null) {
        //return str;
        // return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD h:mm a");
        return moment.tz(str, "America/Bogota").utc().format("MMM D/ YYYY h:mm a");

    }
    return "";
  },
  boolean (str) {
    if (str != null) {
        if(str){
            return 'SÍ';
        }else{
            return 'NO';
        }
    }
    return "NO";
  },
  rfill(str){
	if (str != null) {
		str += '';
		return str.padStart(3, '0');
	}
	return "";
  }
};
