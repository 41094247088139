<template>
  <div>
    <v-checkbox
      v-model="value"
      :false-value="null"
      v-bind="{ ...config, ...$attrs }"
      :label="$t(label) + '*'"
      @click="change"
    >
      <template v-slot:label>
        <div>
          Confirmo que autorizo el registro de información de datos personales y
          acepto
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <a target="_blank" :href="url" @click.stop v-on="on">
                las politicas de privacidad y proteccion de datos personales.
              </a>
            </template>
          </v-tooltip>
        </div>
      </template>
    </v-checkbox>
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgDataPolicy",
  mixins: [AbstractField],
};
</script>
