<template>
      <v-dialog v-model="dialog" :light="true" :dark="false"   persistent max-width="290">
      <v-card>
        <v-card-title class="headline"></v-card-title>
        <v-card-text>{{text}}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" @click="close(false)" text >Cancelar</v-btn>
          <v-btn color="green darken-1" @click="close(true)" text >Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  import { VCard, VCardText, VCardActions, VCardTitle, VSpacer, VIcon, VContainer, VRow, VCol, VBtn, VDialog} from 'vuetify/lib';
  
  export default {
    data(){
        return {
            dialog: true
        }
    },
    components: { VCard, VCardText, VCardTitle, VSpacer, VCardActions, VIcon, VContainer, VRow, VCol, VBtn, VDialog },
    props: ['text', 'resolve'],
    methods: {
      close(accept){
          this.resolve(accept);
          this.$destroy(this.$el)
      }  
    }
  }
</script>
