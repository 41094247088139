import Vue from 'vue'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

const requireComponent = require.context(
  '@/components/base', true, /\.vue$/,
)

requireComponent.keys().forEach(fileName => {
  const componentConfig = requireComponent(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
  )

  Vue.component(`Base${componentName}`, componentConfig.default || componentConfig)
})

const requireComponentLayout = require.context(
  '@/components/layout', true, /\.vue$/,
)

requireComponentLayout.keys().forEach(fileName => {
  const componentConfig = requireComponentLayout(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
  )
  Vue.component(`Layout${componentName}`, componentConfig.default || componentConfig)
})

const requireComponentGui = require.context(
  '@/components/gui', true, /\.vue$/,
)

requireComponentGui.keys().forEach(fileName => {
  const componentConfig = requireComponentGui(fileName)

  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
  )
  // console.log(componentName)
  Vue.component(componentName, componentConfig.default || componentConfig)
})


const requireComponentGui2 = require.context(
  '@/components/inputs', true, /\.vue$/,
)


requireComponentGui2.keys().forEach(fileName => {
  const componentConfig = requireComponentGui2(fileName)
  const componentName = upperFirst(
    camelCase(fileName.replace(/^\.\//, '').replace(/\.\w+$/, '')),
  )
  Vue.component(componentName+'2', componentConfig.default || componentConfig)
})

