<template>
    <div class="d-flex"> <!-- handleInput (e) {
        :disabled="isDisabled()"  :on="on"
        :error="error"
        :error-messages="errorMessages"  v-model="mask"
      this.$emit('input', this.content) :label="$t(options.label) + labelRequired()"
    } -->
      <v-text-field
        :value="mask"
        v-bind="config"
        prepend-inner-icon="mdi-cash"
        

        @change="$emit('change', $event)"
        @keyup="$emit('keyup', $event)"
        @input="handleInput"
        @keypress="onkeypress"
        :error="error"
        :error-messages="errorMessages"

      
      />
      <!--ng-help v-if="options.help" :text="options.help" /-->
    </div>

</template>
<script>
  import currency from 'currency.js';
  import AbstractPlainInput from './AbstractPlainInput'
  export default {
    name: 'PlainInputMoney',
    mixins: [AbstractPlainInput],
    data(){
      return {

      }
    },
    //props: ['value'],
    computed: {
      mask: {
        cache: false,
        get: function(){
            return currency(this.value, { precision: 0, symbol: '' }).format();
        },
        set: function(nv){
            this.value = (''+nv).replace(/\,/g, '');
        }
      } 
    },
    methods: {
      handleInput (e) {
        this.testRules();
        this.$emit('input', (''+e).replace(/\,/g, ''))
      },
      onkeypress (event) {
        const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16 ];
        if(ignore.indexOf(event.keyCode) > -1){
          return true;
        }
        const mask = '[0-9\.\,]+';
        var patt = new RegExp(mask);
        const char = String.fromCharCode(event.keyCode)
        if (!patt.test(char)) {
          	event.preventDefault()
        }
        
      }
    }
  }
</script>
