<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  > 
    <div class="d-flex">
      <!-- item-text="name" -->

      <v-select
        v-model="value"
        v-bind="config"
        :items="items"
        v-on:change="change($event)"
        :label="$t(options.label) + labelRequired()" 
        
        
        :disabled="isDisabled()"
        :error="error" 
        :error-messages="errorMessages"
        :value-comparator="compare"
        return-object
        >
        <template v-slot:item="{item}">
          <span>{{item.name}}</span>
        </template>
        <template v-slot:selection="{item}">
          <span>{{item.name}}</span>
        </template>
      </v-select>
      <slot></slot>
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  import inputHooks from '@/plugins/InputHooks'

  export default {
    name: 'NgSelect',
    mixins: [AbstractField],
    data(){
      return {
        items: null,
        key: 0
      }
    },
    created(){
      if(!this.items){
       // console.log(this.options)
       if(!this.options.blank ){
          const list = this.$store.getters.getList(this.options.url);
          if(!list){
            this.queryOptions('');
          }else{
            this.items = list.items;
            this.keyInput++;
          }
        }

        // console.log('created select')
        if(this.value && this.value.id && !this.items){
          this.items = [JSON.parse(JSON.stringify(this.value))];
        }
      }
     },
   computed: {
      value: {
        cache: false,
        get: function () {
          // console.log('select', this.items)
          if (this.options) {

            if(this.items && this.items.length > 0){
              for(const item of this.items){
                if(this.form[this.options.name] && item.id == this.form[this.options.name].id){
                  return item;
                }
              }
            }
            // console.log('get value')
            return this.form[this.options.name]
          }
          return null
        },
        set: function (nv) {
          //console.log('set.....select')
          this.form[this.options.name] = nv
          // console.log('set value')
          // this.$forceUpdate()
          // this.form._dirty = true
          //this.$forceUpdate();
        }
      }
    },     
     methods: {
      change ($e) {
        //console.log(this.options)
        if (this.form && this.options) {
          this.testRules ($e);
          if (this.options.change) {
            const hook = this.options.change;
            return inputHooks.execute(hook, this, this.options, this.form)
          }
        }        
        this.$emit('change', $e);
        
        //this.testRules ($e);
      },
      formChange (evt) {
        //console.log(this.form[this.options.name])
        // se signa siempre por defecto el primer elemento de la lista
        if(evt.control == this.options.name /*&& !this.form[this.options.name]*/){
          if(this.items && this.items.length){
            //this.form[this.options.name] = this.items[0];
            this.keyInput++;
          }
        }
        if(this.options.depends_of){
          if(this.options.depends_of.indexOf(evt.control) > -1){
            this.updateItems();
          }
        }
      },
      updateItems(){
        this.queryOptions('');
      },
      queryOptions (v) {
        //this.$store.commit('addRequestList', this.options.url);
        //console.log(this.form)
        this.loading = true
        const params = this.options.params || {};
        if(this.options.depends_of){
            for(const index of this.options.depends_of){
              if(Array.isArray(this.form[index])){
                params[index]= this.form[index];
              }else{
                if(!this.form[index]){
                  return;
                }
                params[index] = [this.form[index]]
              }
              //console.log(params)
              /*if(!params[index]){
                return;
              }*/
            }
        }
        if(v){
          params['query'] = v;
        }
        this.$http.post( this.options.url, { data: params }).then(data => {
          const { items } = data;
          //console.log(data)
          //this.$store.commit('deleteRequestList', this.options.url);
          this.$store.commit('addList', { path: this.options.url, items});
          this.items = items;
          this.keyInput++;
        }).catch(err => {
          console.log(err)
        }) .finally(() => (this.loading = false))          
      }
    }
  }
</script>
