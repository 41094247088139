import Vue from 'vue'
import VueMeta from 'vue-meta'
import App from './App.vue'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
//import * as firebase from 'firebase'
import * as inputHooks from '@/hooks/input-hooks'
import HttpClient from '@/plugins/http'
import Auth from '@/plugins/auth'
import axios from 'axios'
import * as moment  from 'moment-timezone';
require('./locales/moment-locales-es');
import VueCountryCode from "vue-country-code";

require("./global-hooks");

import { ValidationProvider, ValidationObserver } from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.use(VueCountryCode);


import DefaultAppBar from './modules/base/components/DefaultAppBar';
import DefaultFiltersChips from './modules/base/components/DefaultFiltersChips';
import NgHelp from './components/NgHelp';
import NgColorCircle from './components/NgColorCircle';
import NgHelpModule from './components/NgHelpModule';

import NgUserGroups from './modules/auth/components/user/NgUserGroups';


import PlainInputMoney from './components/PlainInputMoney';
Vue.component('plain-money-input', PlainInputMoney);

Vue.component('default-app-bar', DefaultAppBar);
Vue.component('ng-filters-chips', DefaultFiltersChips);
Vue.component('ng-user-groups', NgUserGroups);
Vue.component('ng-help', NgHelp);
Vue.component('ng-color-circle', NgColorCircle);
Vue.component('ng-help-module', NgHelpModule);

/*
const firebaseConfig = {
  apiKey: 'AIzaSyAcM68RCToqFSqVLLBy9CFhTSlv4C7y-Ko',
  authDomain: 'bookins-vue.firebaseapp.com',
  databaseURL: 'https://bookins-vue.firebaseio.com',
  projectId: 'bookins-vue',
  storageBucket: 'bookins-vue.appspot.com',
  messagingSenderId: '123072087638',
  appId: '1:123072087638:web:405078fd3bd54de4371df7',
  measurementId: 'G-LGCMSEB5LN'
}
firebase.initializeApp(firebaseConfig)
firebase.analytics()*/

Vue.config.productionTip = false
//const http = new HttpClient(axios)
Vue.prototype.$http = HttpClient; //new HttpClient(axios);
Vue.prototype.$auth = new Auth(Vue.prototype.$http);
Vue.prototype.$moment = moment;

class UserSession{
  setData(data){
    this.data = data;
    // console.log(this.data)
  }
}
class GlobalConfig{
  setData(data){
    this.data = data;
    console.log(this.data)
  }
}
Vue.prototype.$currentUser = new UserSession();
Vue.prototype.$globalConfig = new GlobalConfig();
Vue.prototype.$menu = {};
// console.log('jijijiji')
Vue.use(VueMeta);

import currency from 'currency.js';
const currencyFormat = function (str) {
  if (str != null) {
    return  currency(str, { precision: 2 }).format();
  }
  return "";
};

Vue.filter('currency', currencyFormat);

const dateFormat = function (str) {
  if (str != null) {
    return  str;
  }
  return "";
};

Vue.filter('date', dateFormat);

import modalsManager from './plugins/modal-manager'
Vue.prototype.$modalsManager = modalsManager;

import filters from './plugins/filters'
Vue.prototype.$filters = filters;

import router from './router'
import store from './store'

import TaxManager from './plugins/tax-manager'
Vue.prototype.$taxManager = new TaxManager(store);

import PriceManager from './plugins/price-manager'
import './registerServiceWorker'
Vue.prototype.$priceManager = new PriceManager(Vue.prototype.$taxManager);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')

/*setTimeout(function() {
    window.loadCustomJs();
}, 1000);*/

