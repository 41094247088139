<template>
  <div>
    <v-list-item>
      <v-list-item-content class="text-left">
        {{ $t(label)  + labelRequired() }}
      </v-list-item-content>

      <v-list-item-action>
        <v-radio-group
          :value="value"
          v-bind="{ ...config, ...$attrs }"
          row
          @change="inputEvt"
          @input="inputEvt"
        >
          <v-radio label="No" :value="false"></v-radio>
          <v-radio label="Si" :value="true"></v-radio>
        </v-radio-group>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgSwitch",
  mixins: [AbstractField],
};
</script>
