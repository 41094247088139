<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex">
      <!-- @keydown="onkeypress" -->
      <v-text-field
        v-model="value"
        v-bind="config"
        :label="$t(options.label) + labelRequired()"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        
        :disabled="isDisabled()"
        type="number"
        :error="error"
        :error-messages="errorMessages"
      />
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField';
  import inputHooks from '@/plugins/InputHooks';
  
  export default {
    name: 'NgNumber',
    mixins: [AbstractField],
    methods: {
      /*keyup ($event) {
        // en las versiones mobiles no funcionan adecuadamente el evento keypress y keydown
        const searchRegExp = /[^0-9\.\,]+/gi;
        const replaceWith = '';
        const result = this.form[this.options.name].replace(searchRegExp, replaceWith);
        this.form[this.options.name] = result;
        //console.log(this.form.value)
        event.target.value = result;
        
        if (this.form && this.options) {
          // console.log('.....', this.options.name)
          if (this.options.keyup) {
            const hook = this.options.keyup
            return inputHooks.execute(hook, this, this.options, this.form)
          }
        }
        this.$emit('keyup', $event)
      },*/
      onkeypress (event) {
        const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16 ];
        if(ignore.indexOf(event.keyCode) > -1){
          return true;
        }
        const mask = '[0-9\.\,]+';
        var patt = new RegExp(mask);
        const char = String.fromCharCode(event.keyCode)
        if (!patt.test(char)) {
          	event.preventDefault()
        }
        
      }
    }
  }
</script>
