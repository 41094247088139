export default {
  state: {
    loading: false,
    error: null,
    message: null,
    transaction: null
  },
  mutations: {
    setMessage (state, payload) {
      state.message = payload
    },
    setLoading (state, payload) {
      state.loading = payload
    },
    setError (state, payload) {
      state.error = payload
    },
    clearError (state) {
      state.error = null
    },
    setTransaction (state, payload) {
      state.transaction = payload
    }
  },
  actions: {
    clearError ({ commit }) {
      commit('clearError')
    },
    setError ({ commit }, payload) {
      commit('setError', payload)
    }
  },
  getters: {
    transaction (state) {
      return state.transaction
    },
    message (state) {
      return state.message
    },
    loading (state) {
      return state.loading
    },
    error (state) {
      return state.error
    }
  }
}
