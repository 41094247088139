<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    :key="keyInput"
  >
    <div >
      <!--
        dense
        
        hide-details="auto"
        outlined
        :filled="options.filled"
        :dark="options.dark"
        :maxlength="options.maxlength"
        :placeholder="options.placeholder"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"
        
                @keypress="onKeypress"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        -->

    <!--v-input @keyup="keyup" @keydown="onKeypress"
           v-model="value"
        v-bind="config"
    /-->
      <v-text-field outlined dense @keydown="onKeypress" @change="change"
        clearable
        v-model="value"
        :label="$t(options.label) + labelRequired()"
        :error="error"
        :error-messages="errorMessages"
      />
      
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgTextScan',
    mixins: [AbstractField],
    data(){
      return {
        document_info: [],
        info_text: '',
        doc: false
      }
    },
   computed: {
      value: {
        cache: false,
        get: function () {
          if (this.options) {
            
            if (this.options.upper) {
              if (this.form[this.options.name]) {
                return this.form[this.options.name].toUpperCase();
              }
              return this.form[this.options.name]
            } else {
              return this.form[this.options.name]
            }
          }
          return null
        },
        set: function (nv) {
           if (this.options.upper) {
              if (nv) {
                this.form[this.options.name] = nv.toUpperCase();
              }
            }else{
                this.form[this.options.name] = nv
            }
        }
      }
    },
    methods: {

      onKeypress (event) {
         console.log(event.keyCode)
          if(!this.value && event.keyCode == 48){
              event.preventDefault()
              return false;            
          }
          if( event.keyCode == 13){
            console.log(this.document_info)
            this.$emit('documentOk', this.document_info);
            this.document_info = [];
            this.info_text = '';
            this.doc = false;
          }
          if( event.keyCode == 16 || event.keyCode == 32) {
              event.preventDefault()
              return false;
          }
          if(event.keyCode == 9 || event.keyCode == 16) {
            this.document_info.push(this.info_text);
            this.info_text = '';
            if(!this.doc){
              this.doc = true;
            }
              event.preventDefault()
              return false;
          }
          this.info_text += event.key;
          if(this.doc){
              event.preventDefault()
              return false;
          }
       /* const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16]
        if (ignore.indexOf(event.keyCode) > -1) {
          return true
        }
        if (this.options.mask) {
          const patt = new RegExp(this.options.mask)
          const char = String.fromCharCode(event.keyCode)
          if (!patt.test(char)) {
            event.preventDefault()
          }
        }*/
      }
    }
  }
</script>
