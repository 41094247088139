<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex">

      <v-text-field
        v-model="value"
        prepend-inner-icon='mdi-phone'
        v-bind="config"
        :label="$t(options.label) + labelRequired()"
        @keypress="onKeypress"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        type="tel"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"
      />
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgPhone',
    mixins: [AbstractField],
    methods: {
      onKeypress (event) {
        const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16]
        if (ignore.indexOf(event.keyCode) > -1) {
          return true
        }
        const mask = '[0-9\(\)\+\s]+';
        var patt = new RegExp(mask);
        const char = String.fromCharCode(event.keyCode);
        this.char = char;
        if (!patt.test(char)) {
          	event.preventDefault()
        }
      }
    }
  }
</script>
