<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-text-field
        :value="maskCurrency"
        v-bind="{ ...config, ...$attrs }"
        prepend-inner-icon="mdi-cash"
        :label="$t(label) + labelRequired()"
        v-on="$listeners"
        @input="inputEvt"
        type="text"
        @keyup="keyup"
        @keypress="keyup"
        @keydown="keyup"
        :error-messages="errors"
       
      />
      <!--   @keyup="keyup"
        @keypress="keyup"
        @keydown="keyup" -->
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import currency from "currency.js";
import AbstractField from "./AbstractField";
export default {
  name: "NgMoney2",
  mixins: [AbstractField],
  data() {
    return {
      //maskCurrency: "",
      keyRender: 1,
    };
  },
  created() {
    this.mask = "[^0-9.,]+";
  },
  computed: {
    maskCurrency: {
      cache: false,
      get: function () {
        let v = this.value;
        if(!v){
          v = '';
        }  
        return currency(v + "", {
          precision: 0,
          symbol: "",
        }).format();
      },
      set: function (nv) {
        //console.log('jijijijij')
        //this.value = ("" + nv).replace(/,/g, "");
      },
    },
  },
  methods: {
    keyup(event){
      //console.log(event)
      if(['0','1','2','3','4','5','6','7','8','9', ".", ",", "Backspace", "Delete", "Enter"].indexOf(event.key) == -1){
        event.preventDefault();
        return false
      }

      //this.inputEvt(this.value);
 
      
    },
    inputEvt(e) {
      this.$nextTick().then(() => {
        if (!e) {
          e = "";
          this.$emit("input", e);
        } else {
          // console.log(e, ("" + e).replace(/,/g, ""));
          this.$emit("input", ("" + e).replace(/,/g, ""));
        }
      });
    },
    /*inputEvt(e) {
      console.log(e);
        if (!e) {
          e = "";
          this.$emit("input", e + "");
        } else {
           
          const regex = /[^0-9.,]+/gm;
          const val = ("" + e).replace(",", "") // this.value.replace(regex, '');
          console.log(e, val);
          //this.$emit("input", ("" + val).replace(/,/g, ""));
            this.$emit("input", val);
          
          
        }
    }*/
  },
};
</script>
