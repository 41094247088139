<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <v-list-item>
        <!--v-checkbox
            v-model="value" 
            v-bind="config"
            :label="$t(options.label)"
            @click="change"
        ></v-checkbox-->
         <v-list-item-content class="text-left">
            {{options.label + labelRequired()}}
          </v-list-item-content>
                          
        <v-list-item-action>
        <v-radio-group  v-model="value" row @change="change" @input="inputEvt" >
          <v-radio
            label="No"
            :value="false" 
          ></v-radio>
          <v-radio
            label="Si"
            :value="true"
          ></v-radio>
        </v-radio-group>
    
        <!--v-switch
          v-model="value"
          inset
          flat
          @click="change"
        >
            <template v-slot:label>
                <span style="color:rgba(0, 0, 0, 0.87) !important" v-if="!value">No</span>
                <span style="color:rgba(0, 0, 0, 0.87) !important" v-else>Sí</span>
            </template>
        </v-switch-->
        </v-list-item-action>
    </v-list-item>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgSwitch',
    mixins: [AbstractField]
  }
</script>
