<template>
  <div class="d-flex">
    <vue-country-code
      @onSelect="onSelect"
      :preferredCountries="['co', 'us', 'es', 'mx', 'ec']"
    >
    </vue-country-code>
    <v-text-field
      v-model="value"
      :prefix="prefix"
      v-bind="{ ...config, ...$attrs }"
      :label="$t(label) + labelRequired()"
      type="tel"
      maxlength="10"
      

    />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgPhoneIso",
  mixins: [AbstractField],
  data() {
    return {
      prefix: "",
    };
  },
  created() {
    this.mask = "[^0-9]+";
  },
  /*computed: {
    value: {
      cache: false,
      get: function () {
        return ("" + this.form[this.options.name]).replace(/[^0-9]/g, "");
      },
      set: function (nv) {
        this.form[this.options.name] = ("" + nv).replace(/[^0-9]/g, "");
        //this.$emit('input', this.form[this.options.name]);
      },
    },
  },*/
  methods: {
    onSelect(evt) {
      this.prefix = "+" + evt.dialCode;
    },
  },
};
</script>

<style>
.v-text-field--filled .v-text-field__prefix,
.v-text-field--filled .v-text-field__suffix {
  max-height: unset;
  margin-top: unset;
}
</style>