<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      :rules="rules"
      style="width: 100%"
    >
      <v-select
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :items="itemsList"
        :error-messages="errors"
        @input="inputEvt"
        v-on="$listeners"
        :label="$t(label) + labelRequired()"
        :item-text="'name'"
        :value-comparator="compare"
        return-object
        v-if="!noLabel"
      >
      </v-select>
      <v-select v-else
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :items="itemsList"
        :error-messages="errors"
        @input="inputEvt"
        v-on="$listeners"
        :item-text="'name'"
        :value-comparator="compare"
        return-object
       
      >
      </v-select>
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgSelectOptions",
  mixins: [AbstractField],
  props: ["items"],
  created() {
    //this.items = this.options.items;
  },
  computed: {
    itemsList() {
      return this.items || this.options.items;
    },
    /*value: {
      cache: false,
      get: function () {
        if (this.options) {
          // console.log('get value')
          if (this.form[this.options.name] && this.form[this.options.name].id) {
            return this.form[this.options.name];
          } else {
            if (this.form[this.options.name]) {
              for (const o of this.options.items) {
                if (o.id == this.form[this.options.name]) {
                  return o;
                }
              }
            }
          }
        }
        return null;
      },
      set: function (nv) {
        this.form[this.options.name] = nv;
      },
    },*/
  },
};
</script>
