<template>
    <v-col cols="12" :md="options.size" class="form-col" v-if="isVisible()" :key="keyInput">
    <div class="d-flex">
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false"  transition="scale-transition" offset-y  min-width="290px" max-width="290px">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="value"
            v-bind="config"
            prepend-inner-icon='mdi-clock-outline'
            :label="$t(options.label) + labelRequired()"
            :disabled="isDisabled()"
            :error="error" 
            :error-messages="errorMessages"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-time-picker v-model="value" ref="picker" ampm-in-title full-width :max="max" @change="save" @click:minute="$refs.menu.save(value)"  >
        </v-time-picker>
      </v-menu>
      <ng-help v-if="options.help" :text="options.help" />
    </div>
    </v-col>

</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgTime',
    mixins: [AbstractField],
    data: () => ({
      date: '', //new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      max: null
    }),
    methods: {
      save (evt) {
        console.log(evt)
        this.form[this.options.name] = evt;
      }
    }
    /*created(){
      this.rules = this.getRules();
      this.testRules();
      //console.log(this.value)
      if(!this.value){
        this.value = ''; //this.date;
      }
      this.max = '2030-12-31'
   },*/
   /* computed:{
        value: {
          cache: false,
          get: function(){
            //console.log(this.form[this.options.name], 'lol')
              if(this.options){
                return dateFormat(this.form[this.options.name]);
              }
              return null;
          },
          set: function(nv){
            console.log('set...', this.form[this.options.name], nv)
              this.form[this.options.name] = dateFormat(nv);
              this.form['_dirty'] = true;
          }
      } 
    },*/
    /* watch: {
      menu (val) {
        val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
      },
    },*/
  }
</script>
