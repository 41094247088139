<script>
import NgText from "./NgText";

export default {
  name: "NgInteger",
  mixins: [NgText],
  created() {
    this.mask = "[^0-9]+";
  },
  methods: {
    getType() {
        return "number";
    }
  },
};
</script>
