import inputHooks from './../../plugins/InputHooks'

import { NgValidators,  getRuleValidation } from './../Validators';


export default {
   data () {
      return {
        rulesDefault: [],
        error: false,
        errorMessages: '',
        rules: null,
        config: {},
        keyInput: 0,
        defaultConfig: {
          filled: false,
          flat: true,
          outlined: true,
          // color: 'purple',
          // hint: 'For example, flowers or used cars',
          // 'prepend-inner-icon': 'mdi-map-marker',
          // 'append-icon': 'mdi-map-marker',
          clearable: true,
          // label: 'Field',
          'hide-details': 'auto',
          maxlength: 1024,
          minlength: 0,
          placeholder: '',
          dark: false,
          dense: true,
          name: 'field1',
          upper: false,
          size: 12
        }
      }
   },
   // ['options', 'form', 'size', 'forms'],
   props: {
     options: Object,
     controlName: String,
     form: {
       type: Object,
       default: function () {
        return { field1: '' }
       }
     },
     forms: {
       type: Object
     },
     size: {
       type: Number,
       default: 12
     }
   },
   mounted () {
     //console.log('mounted...')
    if(this.form && typeof this.form.pushInput != 'undefined'){
      this.form.pushInput(this)
      this.testRules()
    }
   },
   created () {
     // console.log('created 1', this.options.name)
     //this.rules = 
     this.config = Object.assign(this.defaultConfig, this.options)
     this.getRules()
     this.form.on('change', this.formChange.bind(this))
     
   },
   
   computed: {
      value: {
        cache: false,
        get: function () {
          if (this.options) {
            // console.log('get value')
            return this.form[this.options.name]
          }
          return null
        },
        set: function (nv) {
          this.form[this.options.name] = nv
          // console.log('set value')
          // this.$forceUpdate()
          // this.form._dirty = true
          //this.$forceUpdate();
        }
      }
    },
    methods: {
      updateKey(){
        this.keyInput++
      },
      setInitialItemsFromValue(){

        this.$forceUpdate();
      },
      formChange (evt) {
        //console.log('change: ' + this.options.name, this.form[this.options.name], this.value)
        //console.log('select abstract')
        this.setInitialItemsFromValue();
        this.testRules()
        // this.$parent.checkInputsDependencies()
        // this.updateKey();
      },
      isVisible () {
        if (this.form && this.options) {
          if (this.options.show) {
            // console.log('hook show: ' + this.options.name)
            const hook = this.options.show
            // return window[hook](this.form, this.options)
            return inputHooks.execute(hook, this, this.options, this.form)
          } else {
            return true
          }
        } else {
          return false
        }
      },
      isDisabled () {
        if (this.form && this.options) {
          if (this.options.disabled) {
            const hook = this.options.disabled
            // return window[hook](this.form, this.options)
            return inputHooks.execute(hook, this, this.options, this.form)
          }
        }
        return false
      },
      testRules () {
        //console.log(this.options.name, this.rules.length)

        if(!this.rules || this.rules.length == 0){
          if (this.form._errors && this.form._errors.indexOf(this.options.name) >= 0) {
            const i = this.form._errors.indexOf(this.options.name)
            this.form._errors.splice(i, 1)
          }

          this.error = false
          this.errorMessages = ''
          if (this.form._errors.length === 0) {
              this.form.setValid(true)
          }
          return;     
        }
        // console.log('test rules', this.options.name,this.rules.length)
        for (const r of this.rules) {
          const result = r(this.form[this.options.name], this.form)
          if (result !== true) {
            if (this.form._errors.indexOf(this.options.name) === -1) {
              //this.errorMessages = result
              this.form.setValid(false)
              this.form.pushError(this.options.name)
            }
            return
          }
          if (this.form._errors && this.form._errors.indexOf(this.options.name) >= 0) {
            const i = this.form._errors.indexOf(this.options.name)
            this.form._errors.splice(i, 1)
          }

          this.error = false
          this.errorMessages = ''
          if (this.form._errors.length === 0) {
              this.form.setValid(true)
          }
        }
        
      },
      keyup ($event) {
        //console.log('keyup')
        if (this.form && this.options) {
          // console.log('.....', this.options.name)
          if (this.options.keyup) {
            const hook = this.options.keyup
            return inputHooks.execute(hook, this, this.options, this.form)
          }
        }
        this.$emit('keyup', $event)
      },
      change ($e) {
        //console.log('change', $e)
        //
        if (this.form && this.options) {
          if (this.options.change) {
            const hook = this.options.change
            return inputHooks.execute(hook, this, this.options, this.form)
          }
        }
        
        this.$emit('change', $e);
        //this.inputEvt ($e);
      },
      inputEvt (e) {
        //console.log(this.rules)
        if(this.rules && this.rules.length > 0) {
          // console.log('input validate...', this.options.name)
           // console.log(this.form._errors)
          for (const r of this.rules) {
            
            const result = r(e, this.form)
            //console.log('validate: ', e)
            if (result !== true) {
              //console.log('error....')
              this.error = true
              this.form.setValid(false)
              if (this.form._errors.indexOf(this.options.name) === -1) {
                this.form.pushError(this.options.name)
              }
              this.errorMessages = result
              return
            }
          }
          if (this.form._errors && this.form._errors.indexOf(this.options.name) >= 0) {
            const i = this.form._errors.indexOf(this.options.name)
            this.form._errors.splice(i, 1)
          }
          this.error = false
          this.errorMessages = ''
          // console.log(this.form._errors.length)
          if (this.form._errors.length === 0) {
            this.form.setValid(true)
          }
        }
      },
      updateRules () {
          const rules = []
          // console.log(this.config.validate)
          if (this.config && this.config.validate) {
            for (const v of this.config.validate) {
              rules.push(getRuleValidation(v))
            }
          }
          for(const r of this.rulesDefault){
            rules.push(r)
          }
          this.rules = rules;
          //return rules
      },
      getRules () {
          const rules = []
          // console.log(this.config.validate)
          if (this.config && this.config.validate) {
            for (const v of this.config.validate) {
              rules.push(getRuleValidation(v))
            }
          }
          for(const r of this.rulesDefault){
            rules.push(r)
          }
          this.rules = rules;
          //return rules
      },
      labelRequired () {
          if (this.options.required) {
            return '*'
          }
          return ''
      },
      compare (a, b) {
        // console.log(a,b)
            if (a && b) {
                if (a.id && b.id && a.id === b.id) {
                    return true
                }
                if (a === b) {
                    return true
                }
                if (a.id == b){
                  return true;
                }
            }
            return false
        },
        getText (item) {
            return item.name
        }
    }
}
