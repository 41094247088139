import * as firebase from 'firebase';



export default {
  state: {
    auth: null,
  },
  mutations: {
    setAuth (state, payload ) {
      console.log(payload)
      state.auth = payload
    },
  },
  actions: {
    autoSignInAuth ({ commit }, payload) {
      commit('setAuth', payload)
    },
    logoutAuth ({ commit }) {
      //firebase.auth().signOut()
      localStorage.removeItem('auth')
      localStorage.removeItem('token')
      commit('setAuth', null)
      console.log('logout')
      window.location.href = '/'
    }
  },
  getters: {
    auth (state) {
      return state.auth
    },
    is_super(state){
    if(state.auth){
        if(state.auth.groups.find((item) => item.id == 'bcef010c-c05d-11ea-b8eb-02027dc1a8b0')){
          return true;
        }
      }
      return false
    },
    is_investor(state){
      if(state.auth){
        if(state.auth.groups.find((item) => item.id == '58dda744-e642-11ea-af77-02027dc1a8b0')){
          return true;
        }
      }
      return false;
    }

  }
}
