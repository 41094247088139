import inputHooks from '@/plugins/InputHooks'

inputHooks.register('hook_email_is_visible', function (hookName, inputCompnent, inputOptions, ngForm) {

    // ngForm.first_name = ngForm.value.username;
    if (ngForm.value && ngForm.value.username && ngForm.value.username.length > 4) {
        return false;
    }
    return true
});

inputHooks.register('hook_birthday_is_disabled', function (hookName, inputCompnent, inputOptions, ngForm) {

    if (ngForm.value && ngForm.value.first_name && ngForm.value.first_name.length > 4) {
        return true;
    }
    return false
});


inputHooks.register('hook_username_chage', function (hookName, inputCompnent, inputOptions, ngForm) {
    console.log('hook_username_chage')
    ngForm.username2 = (new Date()).toString()
    /*if (ngForm.value && ngForm.value.first_name && ngForm.value.first_name.length > 4) {
        return true;
    }
    return false*/
});


inputHooks.register('hook_change_day', function (hookName, inputCompnent, inputOptions, ngForm) {

    console.log('jijijijij.......', ngForm.value)   
    return true
});