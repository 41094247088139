<template>
    <v-col cols="12"  :md="options.size" class="form-col" v-if="isVisible()" :key="keyInput">
    <div class="d-flex">
      <v-menu ref="menu" v-model="menu" :close-on-content-click="false"  transition="scale-transition" offset-y  min-width="290px" >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="value"
            v-bind="config"
            prepend-inner-icon='mdi-calendar'
            :label="$t(options.label) + labelRequired()"
            :disabled="isDisabled()"
            :error="error" 
            :error-messages="errorMessages"
            readonly
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker v-model="value" ref="picker" type="date"  min="1920-01-01" :max="max" @change="save" @input="menu = false" locale="es"  no-title>
        </v-date-picker>
      </v-menu>
      <ng-help v-if="options.help" :text="options.help" />
    </div>
    </v-col>

</template>
<script>

  import AbstractField from './AbstractField'
  import * as moment  from 'moment-timezone';
  
  export default {
    name: 'NgDate',
    mixins: [AbstractField],
    data: () => ({
      date: '', //new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      max: null
    }),
    created(){
      //this.$refs.picker.activePicker = 'YEAR';
      if (this.options.birthday) {
        this.max = new Date().toISOString().substr(0, 10);
      }
      if ( this.options.current ) {
        this.value = new Date().toISOString().substr(0, 10);
      }
    },
    methods: {
      save (evt) {
        console.log(evt)
        this.form[this.options.name] = evt;
        this.$emit('change', evt);
      },
      dateFormat (str) {
      	if (str && str != null) {
      		// return moment.tz(str, "America/Bogota").utc(); // .format("MMM D/ YYYY");
      		// return moment(str).utc();
      		return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD");
      	}
      	return "";
      }
    },
    /*created(){
      this.rules = this.getRules();
      this.testRules();
      //console.log(this.value)
      if(!this.value){
        this.value = ''; //this.date;
      }
      this.max = '2030-12-31'
   },*/
    computed:{
      value: {
        cache: false,
        get: function(){
          if(this.options){
            return this.dateFormat(this.form[this.options.name]);
          }
          return null;
        },
        set: function(nv){
          this.form[this.options.name] = this.dateFormat(nv);
        }
      } 
    },
    watch: {
      menu (val) {
        if(val && this.config.activePicker){
          setTimeout(() => {
            this.$refs.picker.activePicker = this.config.activePicker; //'YEAR'
          })
        }
      },
    },
  }
</script>
