<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex">
      <v-text-field
        v-model="mask"
        v-bind="config"
        prepend-inner-icon="mdi-cash"
        :label="$t(options.label) + labelRequired()"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        @keypress="onkeypress"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"
      />
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import currency from 'currency.js';
  import AbstractField from './AbstractField'
  export default {
    name: 'NgMoney',
    mixins: [AbstractField],
    data(){
      return {
        maskValue: '',
        keyRender: 1
      }
    },
    created(){
      this.rules = this.getRules();
      this.testRules();
   },
    mounted(){
      this.maskValue = this.form[this.options.name];
      //console.log(currency('1500', { precision: 0 }).format())
      //console.log(currency('$1500', { precision: 0, symbol: '' }).format())
    },
    computed: {
      mask: {
          cache: false,
          get: function(){
              return currency(this.maskValue, { precision: 0, symbol: '' }).format();
          },
          set: function(nv){
              this.maskValue = nv;
              this.form[this.options.name] = (''+nv).replace(/\,/g, '');
          }
      } 
    },
    methods: {
      onkeypress (event) {
        const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16 ];
        if(ignore.indexOf(event.keyCode) > -1){
          return true;
        }
        const mask = '[0-9\.\,]+';
        var patt = new RegExp(mask);
        const char = String.fromCharCode(event.keyCode)
        if (!patt.test(char)) {
          	event.preventDefault()
        }
        
      }
    }
  }
</script>
