<template>
  <!-- v-if="isVisible()" :key="keyInput" -->
    <v-col cols="12"  :sm="options.size" class="form-col" >
    <div>
      <v-divider class="mb-2"></v-divider>
    <div class="text-h3">Grupos</div>
    <v-row dense>
      <v-col cols="12" sm="6" v-for="g in groups" dense>
        <v-checkbox
              dense
              :label="g.name"
              @change="changeValue($event, g)" 
              :true-value="true" 
              :false-value="false" 
              :input-value="isChecked(g)"
              hide-details
            ></v-checkbox>
      </v-col>
    </v-row>
    <v-divider class="mt-2"></v-divider>
    </div>
    </v-col>

</template>
<script>
  
  export default {
    name: 'NgUserGroups',
    // mixins: [AbstractField],
    data: () => ({
      groups: [],
      value: []
    }),
    props: {
      form: Object,
      options: {
        type: Object,
        default: {
          size: 12
        }
      }
    },
    mounted(){
      this.getGroups();
      this.form.on('change', (val) => {
        if(val.value.user_groups) {
          this.value = val.value.user_groups;
        }
      });

    },
    methods: {
      isChecked(prop) {
        if ( this.value.indexOf(prop.id) >= 0) {
          return true;
        }
        return false;
      },
      changeValue($event, prop) {
        //console.log($event, prop)
        if ( this.value.indexOf(prop.id) >= 0) {
          if ($event === false) {
            const index = this.value.indexOf(prop.id);
            this.value.splice(index, 1);
          }
        } else {
          if ($event === true) {
            this.value.push(prop.id);
          }
        }
        this.form[this.options.name] = this.value;
      },
      getGroups(){
        this.$http.post('api/v1/admin/group/list', {}).then((data) => {
          this.groups = data.items;
          //console.log(this.form.user_groups)
          //this.value = this.form.user_groups;
         
        })        
      }
    }
   
  }
</script>
