<template>
  <div :key="key">

    <v-chip
      v-for="item in filters"
      class="mr-2"
      close
      @click:close="cleanFilter(item.key)"
      :key="item.key"
      
    >
      {{$t(item.name)}}: {{item.value}}
    </v-chip>

</div>
</template>

<script>

  export default {
    name: 'ng-filters-chips',
    data: () => ({
      key: 1,
      //filters: []
    }),
    props: ['form', 'pageConfg'],
    computed:{
      filters() {
        return this.showFilters();
      }
    },
    mounted(){
      this.form.on('change', (val) => {
        // console.log(val)
        this.showFilters();
      })
      //console.log(this.pageConfg)
    },
    
   

    methods: {
      isDate(field){
        return typeof field.from != 'undefined' || typeof field.to != 'undefined'
      },
      isArray(field){
        return Array.isArray(field)
      },
      cleanFilter(key){
        // console.log('clean filter...')
        this.form[key] = '';
        this.key++;
        this.$emit('change')
      },
      getNameFilter(key){
        return this.pageConfg.filters.form[key].label;
      },
      showFilters() {
        const filters = [];
        //console.log('show...............')
        for(const i in this.form.value) {
          if(this.form.value[i] != ''){

            if(this.form.value[i].id ){
              filters.push({
                    key: i,
                    name: this.getNameFilter(i),
                    value: this.form.value[i].name
                  })
            }else{
              if(this.isDate(this.form.value[i])){
                let v = ''
                if(this.form.value[i].from){
                  v = ' (desde)' + this.form.value[i].from;
                }
                if(this.form.value[i].to){
                  if(v == ''){
                    v = '(hasta)' + this.form.value[i].to;
                  }else{
                    v += ' - (hasta)' + this.form.value[i].to;
                  }
                }
                if(v != ''){
                  filters.push({
                      key: i,
                      name: this.getNameFilter(i),
                      value: v
                    })
                }
              }else{
                if(this.form.value[i] != '' && !this.isDate(this.form.value[i])) {
                  if(this.isArray(this.form.value[i])){
                    const mapVal = this.form.value[i].map( item => item.name);
                    const str = mapVal.join(', ');

                    filters.push({
                      key: i,
                      name: this.getNameFilter(i),
                      value: str
                    })

                  }else{
                    filters.push({
                      key: i,
                      name: this.getNameFilter(i),
                      value: this.form.value[i]
                    })
                  }
                }
              }
            }
          }
        }
        this.key++;
        //this.filters = filters;
        //console.log(this.key)
        return filters;
        //
      }
      
    }
  }
</script>
