<template>
  <v-content>
    <router-view :key="$route.fullPath" />
  </v-content>
</template>

<script>
  export default {
    name: 'LayoutCoreViewBasic',
  }
</script>
