<template>
  <v-content>
    <router-view :key="$route.fullPath" />

    <layout-core-footer />
  </v-content>
</template>

<script>
  export default {
    name: 'LayoutCoreView',
  }
</script>
