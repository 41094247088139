<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex">
      <!-- v-on:change="change($event)" -->
      <v-autocomplete   
        v-model="value" 
        :loading="loadingSearch"
         v-bind="config"
         chips small-chips deletable-chips multiple
        :filter="filterFnc"
        :items="itemsServer"
        :label="$t(options.label) + labelRequired()"  
        :item-text="getText"
        :open-on-clear="true"
        :error="error" 
        :error-messages="errorMessages"
        :search-input.sync="search"
        :no-filter="true"
        return-object>

      </v-autocomplete>
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>

  import AbstractField from './AbstractField'
  export default {
    name: 'NgChips',
    mixins: [AbstractField],
    data(){
      return {
          itemsServer: [],
          loadingSearch: false,
          search: null,
      }
    },
    watch: {
      search (val) {
        if(val && val !== this.value && (!this.value || (this.value && val != this.value.name))){
          this.querySelectionsSearch(val);
        } 
      }
    },
    created(){
     if(this.value && this.value.length > 0 && this.itemsServer.length == 0){
        this.itemsServer = JSON.parse(JSON.stringify(this.value));
      }
      console.log(this.options)
    },
    methods:{
      filterFnc (item, queryText, itemText) {
          const parts = queryText.split(' ')
          for (const part of parts) {
            if (itemText.toLowerCase().indexOf(part.toLowerCase()) < 0) {
              return false
            }
          }
          return true
      },
      querySelectionsSearch (v, $store) {
          this.loading = true
          const params = this.options.params || {}
          if (this.options.depends_of) {
            for(const index of this.options.depends_of){
              if(Array.isArray(this.form[index])){

                params[index]= this.form[index];
              }else{
                params[index] = [this.form[index]]
              }
            }
          }
          if (v) {
            params.query = v
          }
          this.$http.post(this.options.url, { data: params }, this, false, false).then(data => {
            console.log(data)
            const { items } = data
            this.itemsServer = items
            if ($store) {
              $store.commit('addList', { path: this.options.url, items })
            }
          }).catch(err => {
            console.log(err)
          }).finally(() => { this.loading = false })
        },
    }
  }
</script>
