<template>
    <v-col cols="12"  :md="options.size" class="form-col" v-if="isVisible()" :key="keyInput">
    <div class="d-flex">
      <v-text-field
            v-model="value"
            v-bind="config"
             type="date"
                  @change="change"
            prepend-inner-icon='mdi-calendar'
            :label="$t(options.label) + labelRequired()"
            :disabled="isDisabled()"
            :error="error" 
            :error-messages="errorMessages"
          
          ></v-text-field>
          
       <ng-help v-if="options.help" :text="options.help" />
    </div>
    </v-col>

</template>
<script>

  import AbstractField from './AbstractField'
  import * as moment  from 'moment-timezone';
  
  export default {
    name: 'NgDateText',
    mixins: [AbstractField],
    data: () => ({
      date: '', //new Date().toISOString().substr(0, 10),
      year: '',
      month: '',
      day: '',
      menu: false,
      modal: false,
      max: null
    }),
    created(){
      //this.$refs.picker.activePicker = 'YEAR';
      if (this.options.birthday) {
        this.max = new Date().toISOString().substr(0, 10);
      }
      if ( this.options.current ) {
        this.value = new Date().toISOString().substr(0, 10);
      }
    },
    methods: {
      onkeypress (event) {
       /* const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16 ];
        if(ignore.indexOf(event.keyCode) > -1){
          return true;
        }*/
        const mask = '[0-9\.\,]+';
        var patt = new RegExp(mask);
        const char = String.fromCharCode(event.keyCode)
        if (!patt.test(char)) {
          	event.preventDefault()
        }else {
          console.log(event.target.value)
          const d = parseInt(event.target.value)
          if(d > 31) {
            event.preventDefault()
          }
        }
        
      },
      nextFocus(field) {
        this.$refs[field].focus();
      },
      save (evt) {
        this.form[this.options.name] = evt;
        this.$emit('change', evt);
      },
      dateFormat (str) {
      	if (str && str != null) {
      		// return moment.tz(str, "America/Bogota").utc(); // .format("MMM D/ YYYY");
      		// return moment(str).utc();
      		return moment.tz(str, "America/Bogota").utc().format("YYYY-MM-DD");
      	}
      	return "";
      }
    },
    /*created(){
      this.rules = this.getRules();
      this.testRules();
      //console.log(this.value)
      if(!this.value){
        this.value = ''; //this.date;
      }
      this.max = '2030-12-31'
   },*/
    computed:{
      value: {
        cache: false,
        get: function(){
          if(this.options){
            return this.dateFormat(this.form[this.options.name]);
          }
          return null;
        },
        set: function(nv){
          this.form[this.options.name] = this.dateFormat(nv);
        }
      } 
    },
     watch: {
       year(v) {
         console.log(v)
       },
        month(v) {
         console.log(v)
       },
        day(v) {
         console.log(v)
       },
        menu (val) {
          if(val && this.config.activePicker){
            setTimeout(() => {
              this.$refs.picker.activePicker = this.config.activePicker; //'YEAR'
            })
          }
        },
    },
  }
</script>
