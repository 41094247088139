const BaseConfig = {
  //url: 'https://app.registro.misionpaz.org/',
  url: window.location.protocol + '//' + window.location.hostname + '/',
  urlProducts: window.location.protocol + '//' + window.location.hostname + '/public/uploads/products/',
  report: ''
  // 'http://3.86.128.118:5084/',
  // url: 'https://registro.misionpaz.org/'
}

//if (window.location.host.indexOf('8080') !== -1 ) {
  BaseConfig.url = 'https://app.serprogreso.com/';
  BaseConfig.url2 = 'http://127.0.0.1:8000/';
  //BaseConfig.url = 'http://3.14.43.3:8081/';
  BaseConfig.report = 'https://reportes.serprogreso.com/';
  //BaseConfig.urlProducts = 'http://3.86.128.118:5098/uploads/images/products/';

  //BaseConfig.url = 'http://35.167.226.224:5098/';
  //BaseConfig.urlProducts = 'http://35.167.226.224:5098/uploads/images/products/';
    //BaseConfig.url = window.location.protocol + '//' + window.location.hostname + ':5086/';
//}

/*
if (window.location.host.indexOf('misionpaz') !== -1 ) {
  //console.log('mpn')
    url: 'https://app.registro.misionpaz.org/'
}*/

class HttpClient{
  constructor (client) {
    
    this.client = client;
  }

  getToken () {
    let token = localStorage.getItem('token');
    if (token) {
      return token;
    }
    return null;
  }

  getHeaders () {
    //const tk = this.getToken();
    const tk = this.getToken();
    let headers = {}
    if(tk) {
      headers = { 'Authorization': ""+ tk, /*"Access-Control-Allow-Origin": "*", mode: 'cors' */ /*, 'authcmp': '6b49f698-01c9-65BB1B8997FAE21476E81EDADB38A'*/ };
    }
    return headers;
  }

  getBaseUrlImagesProducts(){
    return BaseConfig.urlProducts;
  }



  post(path, params, cmp, loading, showMsgSuccess){
    const config = { headers: this.getHeaders() };
    return new Promise((resolve, reject) => {
      if(cmp && loading){
        cmp.$store.commit('setLoading', true);
      }
      let url = BaseConfig.url + path;
      if(path.indexOf('http') >= 0){
        url = path;
      }

      this.client.post(url, params, config).then(response => {
        // console.log(response)
        if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(response.data.success > 0) {
          if(cmp && showMsgSuccess) {
            cmp.$store.commit('setMessage',  response.data.msg );
          }
          resolve(response.data);
        }else {
          if(cmp && response.data.msg){
            //console.error(response.data.msg)
            cmp.$store.commit('setError', response.data.msg);
          }else{
            if(cmp){
              cmp.$store.commit('setError', 'Ha ocurrido un error');
            }
          }
          resolve(response.data);
        }
      }).catch(e => {
        if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(cmp){
          cmp.$store.commit('setError', 'Ha ocurrido un error');
        }
        reject(e)
      });
    })
    
  }

  post2(path, params, cmp, loading, showMsgSuccess){
    const config = { headers: this.getHeaders() };
    return new Promise((resolve, reject) => {
      if(cmp && loading){
        cmp.$store.commit('setLoading', true);
      }
      let url = BaseConfig.url2 + path;
      if(path.indexOf('http') >= 0){
        url = path;
      }

      this.client.post(url, params, config).then(response => {
        // console.log(response)
        if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(response.data.success > 0) {
          if(cmp && showMsgSuccess) {
            cmp.$store.commit('setMessage',  response.data.msg );
          }
          resolve(response.data);
        }else {
          if(cmp && response.data.msg){
            //console.error(response.data.msg)
            cmp.$store.commit('setError', response.data.msg);
          }else{
            if(cmp){
              cmp.$store.commit('setError', 'Ha ocurrido un error');
            }
          }
          resolve(response.data);
        }
      }).catch(e => {
        if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(cmp){
          cmp.$store.commit('setError', 'Ha ocurrido un error');
        }
        reject(e)
      });
    })
    
  }
  postBasic (path, params) {
    const config = { headers: this.getHeaders() };
    return this.client.post(BaseConfig.url + path, params, config);
  }

  queryReport (path, params) {
    const config = { headers: this.getHeaders() };
    return this.client.post(BaseConfig.report + path, params, config);
  }

  get (path, urlBase) {
    const config = { headers: this.getHeaders() };
    if(urlBase){
      return this.client.get(urlBase + path, config);
    }else{
      return this.client.get(BaseConfig.url + path, config);
    }
  }

  get2 (path, params, loading, showMsgSuccess) {
    const config = { headers: this.getHeaders()/*, data: params*/ };
    return new Promise((resolve, reject) => {
      if (this.store && loading) {
        this.store.commit('setLoading', true);
      }
      let url = BaseConfig.url2 + path;
      if (path.indexOf('https') >= 0) {
        url = path;
      }
      if (params) {
        if (url.indexOf('?') >= 0) {
          url += '&' + this.serializeQuery(params);
        } else {
          url += '?' + this.serializeQuery(params);
        }
      }
      this.client.get(url, config).then(response => {
        if (response.data.success > 0) {
          if (showMsgSuccess) {
            this.store.commit('setMessage', response.data.msg);
          }
            resolve(response.data);
        } else {
          if (this.store && response.data.msg) {
            if (showMsgSuccess) {
              this.showError(response.data.msg);
            }
          } else {
            if (showMsgSuccess) {
              this.showError('Ha ocurrido un error');
            }
          }
            resolve(response.data);
        }
      }).catch(e => {
        if (showMsgSuccess) {
          this.showError('Ha ocurrido un error');
        }
        reject(e)
      });
    })
  }
  serializeQuery(params) {
    const value = {};
    for (const i in params) {
      if (Array.isArray(params[i])) {
        value[i] = params[i].map((item) => {
          if (item.id) {
            return { id: item.id };
          }
          return item;
        });
      } else {
        if (params[i] && params[i].id) {
          value[i] = { id: params[i].id };
        } else {
          value[i] = params[i];
        }
      }
    }
    return "params=" + JSON.stringify(value);
    //return new URLSearchParams(value).toString()
  }

  getUrl(path, params, cmp, loading, showMsgSuccess){
    const config = { headers: this.getHeaders() };
    return new Promise((resolve, reject) => {
      if(cmp && loading){
        cmp.$store.commit('setLoading', true);
      }
      let url = BaseConfig.url + path;
      if(path.indexOf('http') >= 0){
        url = path;
      }
      

      if(path.indexOf('https') >= 0){
        url = path;
      }
      this.client.get(url, config).then(response => {
        // console.log(response)
        if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(response.data.success > 0) {
          if(cmp && showMsgSuccess) {
            cmp.$store.commit('setMessage',  response.data.msg );
          }
          resolve(response.data);
        }else {
          if(cmp && response.data.msg){
            //console.error(response.data.msg)
            cmp.$store.commit('setError', response.data.msg);
          }else{
            if(cmp){
              cmp.$store.commit('setError', 'Ha ocurrido un error');
            }
          }
          resolve(response.data);
        }
      }).catch(e => {
        if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(cmp){
          cmp.$store.commit('setError', 'Ha ocurrido un error');
        }
        reject(e)
      });
    })
    
  }

  /*delete (path, params) {
    const config = { headers: this.getHeaders() };
    return this.client.delete(BaseConfig.url + path, config);
  }*/

  delete (path, params, cmp, loading, showMsgSuccess) {
    const config = { headers: this.getHeaders() };
    return new Promise((resolve, reject) => {
      if(cmp && loading){
        cmp.$store.commit('setLoading', true);
      }
      let url = BaseConfig.url + path;
      if(path.indexOf('https') >= 0){
        url = path;
      }
      this.client.delete(url, config).then(response => {
         //console.log(response)
        if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(response.data.success > 0) {
          if(showMsgSuccess) {
            cmp.$store.commit('setMessage', { text: response.data.msg });
          }
          resolve(response.data);
        }else {
          if(cmp && response.data.msg){
            //console.error(response.data.msg)
            cmp.$store.commit('setError', response.data.msg);
          }else{
            cmp.$store.commit('setError', 'Ha ocurrido un error');
          }
          resolve(data);
        }
      }).catch(e => {
        /*if(cmp && loading){
          cmp.$store.commit('setLoading', false);
        }
        if(cmp && response.data.msg){
            //console.error(response.data.msg)
            cmp.$store.commit('setError', response.data.msg);
          }else{
            cmp.$store.commit('setError', 'Ha ocurrido un error');
        }*/
        //console.log(e)
        reject(e)
        
      });
    })
  }

  upload (path, params) {
    const config = { headers: this.getHeaders() };
    // console.log(config);
    config.headers['Content-Type'] = 'multipart/form-data;boundary=' + Math.random().toString().substr(2);
    return this.client.post(BaseConfig.url + path, params, config);
  }
}
import axios from 'axios'
export default new HttpClient(axios);
