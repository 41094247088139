<template>
  <div class="d-flex align-center">
    <validation-provider
      v-slot="{ errors }"
      :name="label"
      style="width: 100%"
      :rules="{ 'required': { 'allowFalse': allowFalse } }"
    >
      <v-checkbox
        v-model="model"
        v-bind="{ ...config, ...$attrs }"
        :label="$t(label) + labelRequired()"
        :error-messages="errors"
        :false-value="false"
        :true-value="true"
        @change="inputEvt"
      ></v-checkbox>
    </validation-provider>
    <ng-help v-if="help" :text="help" class="ml-2" />
  </div>
</template>
<script>
import AbstractField from "./AbstractField";
export default {
  name: "NgCheck",
  mixins: [AbstractField],
  data() {
        return {
            model: false,
        }
  },
  props: {
    allowFalse: {
      type: Boolean,
      default: true
    }
  },
  watch:{
    value(){
      this.model = this.value;
    }
  },
  methods:{
    inputEvt(evt){
      this.$emit('input', evt);
    }
  }

};
</script>
