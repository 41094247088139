
import { NgValidators,  getRuleValidation } from './Validators';


export default {
   data () {
      return {
        rulesDefault: [],
        error: false,
        errorMessages: '',
        rules: null,
        config: {},
        keyInput: 0,
        defaultConfig: {
          filled: false,
          flat: true,
          outlined: true,
          // color: 'purple',
          // hint: 'For example, flowers or used cars',
          // 'prepend-inner-icon': 'mdi-map-marker',
          // 'append-icon': 'mdi-map-marker',
          clearable: true,
          // label: 'Field',
          maxlength: 1024,
          minlength: 0,
          placeholder: '',
          dark: false,
          dense: true,
          name: 'field1',
          upper: false,
          size: 12
        }
      }
   },
   // ['options', 'form', 'size', 'forms'],
   props: {
     options: Object,
     value: String
   },
   mounted () {
   	this.getRules();
   },
   created () {
     // console.log('created 1', this.options.name)
     
     this.config = Object.assign(this.defaultConfig, this.options)
   },
   methods: {
      /*updateKey(){
        this.keyInput++
      },
      setInitialItemsFromValue(){

        this.$forceUpdate();
      },
      formChange (evt) {
        //console.log('change: ' + this.options.name, this.form[this.options.name], this.value)
        //console.log('select abstract')
        this.setInitialItemsFromValue();
        this.testRules()

      },

      keyup ($event) {
        if (this.form && this.options) {
          if (this.options.keyup) {
            const hook = this.options.keyup
            return inputHooks.execute(hook, this, this.options, this.form)
          }
        }
        this.$emit('keyup', $event)
      },
 
      inputEvt (e) {
        if(this.rules && this.rules.length > 0) {
          for (const r of this.rules) {  
            const result = r(e, this.form)
            if (result !== true) {
              //console.log('error....')
              this.error = true
              this.form.setValid(false)
              if (this.form._errors.indexOf(this.options.name) === -1) {
                this.form.pushError(this.options.name)
              }
              this.errorMessages = result
              return
            }
          }
          if (this.form._errors && this.form._errors.indexOf(this.options.name) >= 0) {
            const i = this.form._errors.indexOf(this.options.name)
            this.form._errors.splice(i, 1)
          }
          this.error = false
          this.errorMessages = ''
          if (this.form._errors.length === 0) {
            this.form.setValid(true)
          }
        }
      },*/
      testRules () {
        //console.log(this.options.name, this.rules.length)
        if(!this.rules || this.rules.length == 0){
          /*if (this.form._errors && this.form._errors.indexOf(this.options.name) >= 0) {
            const i = this.form._errors.indexOf(this.options.name)
            this.form._errors.splice(i, 1)
          }*/

          this.error = false
          this.errorMessages = ''
          /*if (this.form._errors.length === 0) {
              this.form.setValid(true)
          }*/
          return;     
        }
        // console.log('test rules', this.options.name,this.rules.length)
        for (const r of this.rules) {
          const result = r(this.value, {})
          // console.log(result)
          if (result !== true) {
            /*if (this.form._errors.indexOf(this.options.name) === -1) {
              //this.errorMessages = result
              this.form.setValid(false)
              this.form.pushError(this.options.name)
            }*/
             this.error = true;
             this.errorMessages = result;
            return
          }
         
          this.error = false
          this.errorMessages = ''
         
        }
        
      },
      updateRules () {
          const rules = []
          if (this.config && this.config.validate) {
            for (const v of this.config.validate) {
              rules.push(getRuleValidation(v))
            }
          }
          for(const r of this.rulesDefault){
            rules.push(r)
          }
          this.rules = rules;
      },
      getRules () {
          const rules = []
          // console.log(this.config.validate)
          if (this.config && this.config.validate) {
            for (const v of this.config.validate) {
              rules.push(getRuleValidation(v))
            }
          }
          for(const r of this.rulesDefault){
            rules.push(r)
          }
          this.rules = rules;
          //return rules
      },
      labelRequired () {
          if (this.options.required) {
            return '*'
          }
          return ''
      },
      compare (a, b) {
            if (a && b) {
                if (a.id && b.id && a.id === b.id) {
                    return true
                }
                if (a === b) {
                    return true
                }
                if (a.id == b){
                  return true;
                }
            }
            return false
        },
        /*getText (item) {
            return item.name
        }*/
    }
}
