<template>
  <v-col
    cols="12"
    :md="options.size"
    class="form-col"
    v-if="isVisible()"
    :key="keyInput"
  >
    <div class="d-flex">
      <!--
        dense
        clearable
        hide-details="auto"
        outlined
        :filled="options.filled"
        :dark="options.dark"
        :maxlength="options.maxlength"
        :placeholder="options.placeholder"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"
        -->
      <v-text-field
        v-model="value"
        prepend-inner-icon='mdi-email'
        v-bind="config"
        :label="$t(options.label) + labelRequired()"
        @keypress="onKeypress"
        @input="inputEvt"
        @keyup="keyup"
        @change="change"
        type="email"
        :disabled="isDisabled()"
        :error="error"
        :error-messages="errorMessages"
      />
      <ng-help v-if="options.help" :text="options.help" />
    </div>
  </v-col>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgEmail',
    data () {
      return {
        rulesDefault: [
          value => {
               if(!value) {
                 return true
               }
               const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
               return pattern.test(value) || 'Correo Electrónico Invalido'
          }
        ],
        error: false,
        errorMessages: '',
        rules: null,
        config: {},
        keyInput: 0,
        defaultConfig: {
          filled: false,
          flat: true,
          outlined: true,
          'prepend-inner-icon': 'mdi-email',
          clearable: true,
          maxlength: 1024,
          minlength: 0,
          placeholder: 'user@example.com',
          dark: false,
          dense: true,
          name: 'email1'
        }
      }
   },
    mixins: [AbstractField],
    methods: {

      onKeypress (event) {
        const ignore = [113, 8, 46, 9, 35, 36, 37, 38, 39, 40, 45, 20, 16]
        if (ignore.indexOf(event.keyCode) > -1) {
          return true
        }
        if (this.options.mask) {
          const patt = new RegExp(this.options.mask)
          const char = String.fromCharCode(event.keyCode)
          if (!patt.test(char)) {
            event.preventDefault()
          }
        }
      }
    }
  }
</script>
