<template>
  <div>
    <router-view  />
    <!-- :key="$route.fullPath" -->
    
     <!--v-dialog v-model="loading" persistent  width="300">
      <v-card  color="primary" dark >
        <v-card-text>
          <v-progress-linear indeterminate color="white"  class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog-->
    
    <v-dialog v-model="loading" persistent  width="300">
      <v-card  color="primary" dark style="background-color: white !important">
        <v-card-text>
          <v-progress-linear indeterminate color="primary"  class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <template v-if="message != null">
    <v-dialog v-model="message" persistent  width="300">
      <v-card style="background-color: white !important">
        <v-card-text>
          {{message}} 
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn text color="green darken-1" @click="$store.commit('setMessage', null)">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
    
    <template v-if="error">
    <v-dialog v-model="error" persistent  width="300">
      <v-card style="background-color: white !important">
        <v-card-text style="color: red">
          {{error}}
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn color="red" @click="$store.dispatch('clearError')">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </template>
    

    <!--div v-else class="display: flex; justify-content:center; align-items: center" style="height: 100vh">
    <v-progress-circular
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div-->
  </div>
</template>

<script>
  
  import {
    mapState,
  } from 'vuex'
  
  export default {
    name: 'App',
    data: () => ({
      loadingInfo: true
    }),
    metaInfo() {
      // const title = 'Registro - ' + this.org.name;
      return { 
        title: 'Konetzi Móvil',
        /*link: [
          { rel: 'stylesheet', href: '/css/index.css' },
         { rel: 'favicon', href: icon } 
        ]*/
      }
    },
   // mounted () {
      /*this.loadingInfo = true
      this.$http.post('api/booking/config').then((response) => {
         this.loadingInfo = false;
         const org = response.data.org;
         this.$store.commit('SET_COMPANY', org)
         this.loadCss(org.url_css);
         
          let icon = '';
          if(org) {
            icon = org.img_ico;
          }
          if(icon) {
            const favicon = document.getElementById("favicon");
            favicon.href = icon;
          }
      
       });*/
   // },

    mounted () {
      let auth = localStorage.getItem('auth')
      if (auth) {
        auth = JSON.parse(auth)
        this.$store.commit('setAuth', auth)
        this.$currentUser.setData(auth);
        // console.log(this.$currentUser)
        // this.$store.dispatch('autoSignIn', auth)
        
      }
      this.onResize();
      window.addEventListener('resize', this.onResize, { passive: true })

    },
    computed: {
      //...mapState(['auth']),
      loading () {
        return this.$store.getters.loading;
      },
      message () {
        return this.$store.getters.message;
      },
      error () {
        return this.$store.getters.error;
      },
      auth () {
        return this.$store.getters.auth;
      },
      org () {
        // console.log(this.$store.state.company)
        return this.$store.state.company;
      }
    },
    watch: {
      auth: {
        deep: true,
        immediate: true,
         handler (val) {
           console.log('usuario de session', val)
            if (val) {
              this.$currentUser.setData(val);
              this.$http.post('api/get-menu-m', { 'mobil': true}).then((data) => {
                if(data.success){
                  //this.items = response.data.menu;
                  this.$store.commit('setMenues', data.menu);
                }
              });
            }
         }
      }
    },
    methods:{
      onResize() {
        const mobile = window.innerWidth < 960;
        this.$store.commit('setMobile', mobile);
      },
      loadCss(url){
        if(url) {
          let style = document.createElement('link');
          style.type = "text/css";
          style.rel = "stylesheet";
          style.href = url;
          document.head.appendChild(style);
        }
      },
    }
  }
</script>
<style>
  .form-col{
    padding-right: 2px !important;
    padding-left: 2px !important;
  }
  .th-basic{
    font-size: 1.4em;
    font-weight: 500;
  }
  .subtitle-form{
    background-color: #ddd;
  }
</style>
