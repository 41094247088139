<template>
  
    <router-view :key="$route.fullPath" />

    <!--layout-core-footer /-->

</template>

<script>
  export default {
    name: 'LayoutCoreView',
  }
</script>
