<template>
  <div class="d-flex">
    <validation-provider
      v-slot="{ errors }"
      :name="$t(label)"
      :rules="rules"
      style="width:100%"
    >
      <v-textarea
        :value="value"
        v-bind="{ ...config, ...$attrs }"
        :label="$t(label) + labelRequired()"
        :error-messages="errors"
        v-on="$listeners"
        @input="inputEvt"
      ></v-textarea>
    </validation-provider>
    <ng-help v-if="help" :text="help" />
  </div>
</template>
<script>
import NgText from "./NgText";
export default {
  name: "NgTextarea",
  mixins: [NgText],
};
</script>
