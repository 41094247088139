<template>
  <v-content>
    <router-view />
  </v-content>
</template>

<script>
  export default {
    name: 'LayoutCoreViewBasic',
  }
</script>
