<template>
  <span style="display: none"></span>
</template>
<script>
  import AbstractField from './AbstractField'
  export default {
    name: 'NgHidden',
    mixins: [AbstractField]
  }
</script>
